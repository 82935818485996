// store/modules/user.js
import axios from '@/axios'
import router from '@/router'

function getDefaultState() {
  return {
    user: {
      email: '',
      name: '',
      surname: '',
      phone: '',
      address: '',
      country: '',
      city: '',
      zip: null,
      balance: 0,
      createdAt: null,
      steamId: null,
      steamProfileUrl: null,
      communityVisibilityState: null,
      tradeLink: null,
      avatarMedium: null
    },
    isAuth: false,
    purchaseHistory: [],
    depositHistory: [],
    cartContents: [],
    totalSum: 0,
    isLoading: false,
    purchaseLoading: false,
    depositLoading: false,
    error: null
  }
}
const state = getDefaultState()

const getters = {
  fullName: (state) => `${state.user.name} ${state.user.surname}`,
  formattedPhone: (state) => (state.user.phone ? `+${state.user.phone}` : ''),
  cartContents: (state) => state.cartContents,
  totalSum: (state) => {
    let total = 0
    for (let i in state.cartContents) {
      let newPrice
      if (state.cartContents[i].count > 1) {
        newPrice = state.cartContents[i].count * parseFloat(state.cartContents[i].item.price)
      } else {
        newPrice = parseFloat(state.cartContents[i].item.price)
      }
      total += newPrice
    }
    return total.toFixed(2)
  },
  isAuth: (state) => state.isAuth
}

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setUserProfile(state, payload) {
    state.user.email = payload.email
    state.user.name = payload.name
    state.user.surname = payload.surname
    state.user.phone = payload.phone
    state.user.address = payload.address
    state.user.country = payload.country
    state.user.city = payload.city
    state.user.zip = payload.zip
    state.user.balance = payload.balance
    state.user.createdAt = payload.created_at
    state.user.steamId = payload.steamId
    state.user.steamProfileUrl = payload.steam_profile_url
    state.user.communityVisibilityState = payload.community_visibility_state
    state.user.tradeLink = payload.steam_trade_link
    state.user.avatarMedium = payload.avatar_medium
  },
  setLoading(state, payload) {
    state.isLoading = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setPurchaseHistory(state, purchaseHistory) {
    state.purchaseHistory = purchaseHistory
  },
  setPurchaseLoading(state, payload) {
    state.purchaseLoading = payload
  },
  setDepositHistory(state, depositHistory) {
    state.depositHistory = depositHistory
  },
  setDepositLoading(state, payload) {
    state.depositLoading = payload
  },
  setPurchaseError(state, payload) {
    state.error = payload
  },
  setCartContents(state, payload) {
    state.cartContents = payload
  },
  setTotalSum(state, payload) {
    state.totalSum = payload
  },
  setIsAuth(state, payload) {
    state.isAuth = payload
  },
  setSteamTradeLink(state, payload) {
    state.user.tradeLink = payload
  }
}

const actions = {
  async register({ commit }, data) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API}register`, data)
      if (response.status === 200 && response.data.status === 'OK') {
        commit('setIsAuth', true)
        return { success: true, error: null, verified: true }
      } else if (response.status === 202 && response.data.status === 'VERIFY') {
        commit('setError', 'Your registration is being processed. Please check your email for a verification link.')
        commit('setIsVerified', false)
        return {
          success: false,
          error: 'Your registration is being processed. Please check your email for a verification link.',
          verified: false
        }
      } else {
        const errorMessage = response.data.message
        commit('setError', errorMessage)
        return { success: false, error: errorMessage, verified: true }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.'
      const errorStatus = error.response?.data?.status
      commit('setError', errorMessage)
      if (errorStatus === 'VERIFY') {
        commit('setIsVerified', false)
      }
      return { success: false, error: errorMessage, verified: true }
    }
  },
  async login({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API}login`, data)
      if (response.data.status === 'OK') {
        commit('setIsAuth', true)
        await dispatch('fetchProfile')
        await dispatch('fetchPurchaseHistory')
        await dispatch('fetchCartContents')
        return { success: true, error: null }
      } else {
        commit('setError', response.data.message)
        return { success: false, error: response.data.message }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.'
      // const errorStatus = error.response?.data?.status
      commit('setError', errorMessage)
      if (error.response.data.status === 'VERIFY') {
        commit('setIsVerified', false)
      }
      return { success: false, error: errorMessage }
    }
  },
  async fetchProfile({ commit, rootState }) {
    try {
      commit('setLoading', true)
      const { data } = await axios.get(`${process.env.VUE_APP_API}user/profile`, {
        params: {
          currency: rootState.app.currency
        }
      })
      commit('setUserProfile', data)
      commit('setLoading', false)
    } catch (error) {
      commit('setLoading', false)
      commit('setError', error)
      if (error.response.status === 401) {
        commit('setIsAuth', false)
      }
    }
  },

  // async updateProfile({ commit }, payload) {
  //   try {
  //     commit('setLoading', true)
  //     await axios.put(`${process.env.VUE_APP_API}user/profile`, payload)
  //     commit('setUserProfile', payload)
  //     commit('setLoading', false)
  //   } catch (error) {
  //     commit('setLoading', false)
  //     commit('setError', error)
  //   }
  // },

  async fetchDepositHistory({ commit }, filters = {}) {
    try {
      commit('setDepositLoading', true)
      let url = `${process.env.VUE_APP_API}deposit/history`
      let params = {}

      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          params[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = value
        }
      })

      const { data } = await axios.get(url, { params })
      commit('setDepositHistory', data.payload)
      commit('setDepositLoading', false)
      return true
    } catch (error) {
      commit('setDepositLoading', false)
      // console.error('Failed to fetch deposit history:', error);
      return 'FETCH_DEPOSIT_HISTORY_ERROR'
    }
  },

  async fetchPurchaseHistory({ commit }, filters = {}) {
    try {
      commit('setPurchaseLoading', true)
      let url = `${process.env.VUE_APP_API}purchase/history`
      let params = {}

      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          params[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = value
        }
      })

      const { data } = await axios.get(url, { params })
      commit('setPurchaseHistory', data.payload)
      commit('setPurchaseLoading', false)
      return true // Return true when the request is successful
    } catch (error) {
      commit('setPurchaseLoading', false)
      // console.error('Failed to fetch purchase history:', error);
      return 'FETCH_PURCHASE_HISTORY_ERROR' // Return the error when the request fails
    }
  },

  async handlePurchase({ commit, state }, payload) {
    try {
      commit('setPurchaseLoading', true)
      const { data } = await axios.post(`${process.env.VUE_APP_API}purchase/product`, {
        lang_id: state.user.langId,
        currency: payload.currency
      })
      commit('setPurchaseLoading', false)
      return data
    } catch (error) {
      commit('setPurchaseLoading', false)
      commit('setPurchaseError', error)
      throw error
    }
  },

  async fetchCartContents({ commit, rootState }) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_API}cart/contents`, {
        params: {
          currency: rootState.app.currency
        }
      })
      commit('setCartContents', data.cart)

      let total = 0
      for (let i in data.cart) {
        let newPrice
        if (data.cart[i].count > 1) {
          newPrice = data.cart[i].count * parseFloat(data.cart[i].item.price)
        } else {
          newPrice = parseFloat(data.cart[i].item.price)
        }
        total += newPrice
      }
      commit('setTotalSum', total.toFixed(2))
    } catch (error) {
      if (error.response.status === 401) {
        commit('setIsAuth', false)
      }
    }
  },

  async removeFromCart({ commit, dispatch }, { item_id }) {
    try {
      const { data } = await axios.post(`${process.env.VUE_APP_API}cart/remove`, { id: item_id })
      commit('setCartContents', data.cart)
      await dispatch('fetchCartContents')
    } catch (error) {
      if (error.response.status === 401) {
        commit('setIsAuth', false)
        await dispatch('modals/openSignInModal', null, { root: true })
      }
    }
  },

  async addToCart({ commit, dispatch, state }, { id, count = 1 }) {
    try {
      if (!state.isAuth) {
        await dispatch('modals/openSignInModal', null, { root: true })
        return
      }

      const existingItem = state.cartContents.find((i) => i.item_id === id)
      if (existingItem) {
        // Item is already in the cart, don't add it again
        return Promise.reject(`Item already in cart`)
      }

      const { data } = await axios.post(`${process.env.VUE_APP_API}cart/add`, { id, count })
      commit('setCartContents', data.cart)
      await dispatch('fetchCartContents')
      return Promise.resolve(`Item added to cart successfully`)
    } catch (error) {
      if (error.response.status === 401) {
        commit('setIsAuth', false)
        await dispatch('modals/openSignInModal', null, { root: true })
        return Promise.reject(`Error adding item to cart: ${error.message}`)
      }
    }
  },
  async fetchAuthStatus({ commit, dispatch }) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_API}is-auth`)
      commit('setIsAuth', data.authenticated)

      if (data.authenticated) {
        await dispatch('fetchProfile')
        await dispatch('fetchCartContents')
        await dispatch('fetchPurchaseHistory')
        await dispatch('fetchDepositHistory')
      } else {
        commit('setIsAuth', false)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        commit('setIsAuth', false)
      } else {
        // console.error('Failed to fetch authentication status:', error);
        // Handle other errors, e.g., display a generic error message
        this.$toast.error('An error occurred. Please try again later.')
      }
    }
  },

  async logout({ commit }) {
    try {
      await axios.post(`${process.env.VUE_APP_API}logout`)
      commit('resetState')
      router.push({ name: 'Home' })
    } catch (error) {
      console.error('Failed to logout:', error)
    }
  },
  async clearCart({ commit }) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API}cart/clear`)
      if (response.data.status === 'OK') {
        commit('setCartContents', [])
        commit('setTotalSum', 0)
        return true // Return true to indicate successful cart clearing
      } else {
        // console.error('Failed to clear cart:', response.data.message)
        return 'CLEAR_CART_ERROR' // Return an error message to indicate failure
      }
    } catch (error) {
      // console.error('Failed to clear cart:', error)
      return 'CLEAR_CART_ERROR' // Return an error message to indicate failure
    }
  },
  async changeEmail({ commit }, data) {
    try {
      // Make a request to the server to update the user's email
      const response = await axios.post(process.env.VUE_APP_API + 'user/change-email', data)
      if (response.data.status === 'OK') {
        commit('setUserProfile', { ...state.user, email: data.email })
        return response.data
      } else {
        // console.error('Failed to clear cart:', response.data.message)
        return response.data
      }
    } catch (error) {
      return error.response.data
    }
  },

  resetUserState({ commit }) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
