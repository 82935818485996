import axios from '@/axios'
import router from '@/router'

function generateSlug(title) {
  return title.toLowerCase().trim().replace(/\s+/g, '-')
}
const state = {
  pages: [],
  currentPage: {
    title: '',
    header: '',
    content: ''
  }
}

const getters = {
  getPageIdBySlug: (state) => (slug) => {
    const cookieNoticeSlug = state.pages.find((page) => page.slug === 'cookie-notice')
    const cookiePolicySlug = state.pages.find((page) => page.slug === 'cookie-policy')
    if ((slug === 'cookie-notice' || slug === 'cookie-policy') && cookieNoticeSlug) {
      return cookieNoticeSlug.id
    } else if ((slug === 'cookie-notice' || slug === 'cookie-policy') && cookiePolicySlug) {
      return cookiePolicySlug.id
    }
    const page = state.pages.find((page) => page.slug === slug)
    return page ? page.id : null
  }
}

const mutations = {
  SET_PAGES(state, pages) {
    state.pages = pages
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page
  }
}

const actions = {
  async fetchStaticPages({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API}static-pages`)
      const pages = response.data.payload.map((page) => ({
        id: page.id,
        slug: generateSlug(page.title),
        title: page.title
      }))
      commit('SET_PAGES', pages)
    } catch (error) {
      console.error('Error fetching static pages:', error)
    }
  },
  async fetchStaticPageById({ commit, state, dispatch }, pageId) {
    try {
      // If pages data is not available, fetch it
      if (!state.pages || !state.pages.length) {
        await dispatch('fetchStaticPages')
      }

      // Check if the page exists in the state
      const page = state.pages.find((p) => p.id === pageId)
      if (page) {
        // Fetch the page data
        const response = await axios.get(`${process.env.VUE_APP_API}static-pages/${pageId}`)
        commit('SET_CURRENT_PAGE', response.data.payload)
      } else {
        // If the page is not found, navigate to the "Page Not Found" route
        router.replace({ name: 'PageNotFound' })
      }
    } catch (error) {
      console.error(`Error fetching static page with ID "${pageId}":`, error)
      // If there's an error, navigate to the "Page Not Found" route
      router.replace({ name: 'PageNotFound' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
