<template>
  <div class="profile-settings">
    <div class="profile-settings__content">
      <div class="profile-settings__left">
        <div class="profile-info-block">
          <h2 class="profile-info-block__heading profile-settings__heading">{{ $t('Basic Information') }}</h2>
          <div class="profile-info-block__row">
            <label for="email" class="profile-info-block__label">{{ $t('Email') }}:</label>
            <div class="profile-info-block__input-group">
              <div class="profile-info-block__password-toggle">
                <input :type="emailPasswordInputType" id="email" class="profile-info-block__input"
                  :value="user.email" />
                <img class="profile-info-block__password-icon" v-if="!emailPasswordVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('email')" />
                <img class="profile-info-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('email')" />
              </div>
              <button class="profile-info-block__button profile-settings__button" @click="openChangeEmailModal">{{
            $t('Change Email') }}</button>
            </div>
          </div>

          <div class="profile-info-block__row">
            <label for="password" class="profile-info-block__label">{{ $t('Current Password') }}:</label>
            <div class="profile-info-block__input-group">
              <div class="profile-info-block__password-toggle">
                <input :type="passwordPasswordInputType" id="password" class="profile-info-block__input"
                  v-model="currentPassword" />
                <img class="profile-info-block__password-icon" v-if="!passwordPasswordVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('password')" />
                <img class="profile-info-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('password')" />
              </div>
            </div>
          </div>

          <div class="profile-info-block__row">
            <label for="password" class="profile-info-block__label">{{ $t('New Password') }}:</label>
            <div class="profile-info-block__input-group">
              <div class="profile-info-block__password-toggle">
                <input :type="passwordPasswordInputType" id="password" class="profile-info-block__input"
                  v-model="newPassword" />
                <img class="profile-info-block__password-icon" v-if="!passwordPasswordVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('password')" />
                <img class="profile-info-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('password')" />
              </div>
            </div>
          </div>

          <div class="profile-info-block__row">
            <label for="password" class="profile-info-block__label">{{ $t('Confirm Password') }}:</label>
            <div class="profile-info-block__input-group">
              <div class="profile-info-block__password-toggle">
                <input :type="passwordPasswordInputType" id="password" class="profile-info-block__input"
                  v-model="confirmPassword" />
                <img class="profile-info-block__password-icon" v-if="!passwordPasswordVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('password')" />
                <img class="profile-info-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('password')" />
              </div>
              <button class="profile-info-block__button profile-settings__button" @click="changePassword">
                {{ $t('Change Password') }}
              </button>
            </div>
          </div>
        </div>

        <div class="security-block">
          <h2 class="security-block__heading profile-settings__heading">{{ $t('Security') }}</h2>
          <div class="security-block__row">
            <div class="security-block__row-content">
              <span class="security-block__label">{{ $t('Temporarily Block My Account') }}</span>
              <button class="security-block__button profile-settings__button" @click="openBlockAccountModal">
                {{ $t('Block My Account') }}
              </button>
            </div>
          </div>
          <p class="security-block__clarification">
            If you believe that your account has been compromised, you can temporarily block it. We will unblock your
            account by contacting our support.
          </p>
          <div class="security-block__row">
            <span class="security-block__label">{{ $t('Delete Account') }}:</span>
            <button class="security-block__button profile-settings__button" @click="openDeleteAccountModal">
              {{ $t('Delete My Account') }}
            </button>
          </div>
        </div>
      </div>

      <div class="profile-settings__separator"></div>

      <div class="profile-settings__right">
        <!-- <div class="settings-block">
          <h2 class="settings-block__heading profile-settings__heading">{{ $t('Settings') }}</h2>
          <div class="settings-block__row">
            <label for="currency" class="settings-block__label">{{ $t('Currency') }}:</label>
            <div class="sort-dropdown">
              <select id="currency" class="settings-block__select" v-model="currentCurrency">
                <option v-for="currency in currencies" :value="currency.code" :key="currency.code">
                  {{ currency.code }} ({{ currency.symbol }})
                </option>
              </select>
              <div class="arrow-icon"></div>
            </div>
          </div>
          <div class="settings-block__row">
            <label for="language" class="settings-block__label">{{ $t('Language') }}:</label>
            <div class="sort-dropdown">
              <select id="language" v-model="language" @change="selectLanguage($event)">
                <option v-for="language in languages" :value="language.id" :key="language.id">
                  {{ language.text }}
                </option>
              </select>

              <div class="arrow-icon"></div>
            </div>
          </div>
        </div> -->

        <div class="steam-settings-block">
          <h2 class="steam-settings-block__heading profile-settings__heading">{{ $t('Steam Settings') }}</h2>
          <div class="steam-settings-block__row">
            <label for="steam-id" class="steam-settings-block__label">Steam ID:</label>
            <div class="steam-settings-block__input-group">
              <div class="steam-settings-block__password-toggle">
                <input type="text" disabled id="steam-id" class="steam-settings-block__input"
                  :value="user.steamId || 'N/\A'" />
                <!-- <img class="steam-settings-block__password-icon" v-if="!passwordPasswordVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('steam-id')" />
                <img class="steam-settings-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('steam-id')" /> -->
              </div>
              <a :href="'https://steamcommunity.com/profiles/' + user.steamId" target="_blank"
                class="steam-settings-block__button profile-settings__button">{{ $t('Open Steam Profile') }}</a>
            </div>
          </div>
          <div class="steam-settings-block__row">
            <label for="trade-link" class="steam-settings-block__label">{{ $t('Trade Link') }}:</label>
            <div class="steam-settings-block__input-group">
              <div class="steam-settings-block__password-toggle">
                <input :type="tradeLinkInputType" id="trade-link" class="steam-settings-block__input" disabled
                  :value="user.tradeLink" />
                <img class="steam-settings-block__password-icon" v-if="!tradeLinkVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('trade-link')" />
                <img class="steam-settings-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('trade-link')" />
              </div>
              <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" target="_blank"
                class="steam-settings-block__button profile-settings__button">{{ $t('Change Trade Link')
                }}</a>
            </div>
          </div>
          <div class="steam-settings-block__row">
            <label for="trade-link" class="steam-settings-block__label">{{ $t('New Link') }}:</label>
            <div class="steam-settings-block__input-group">
              <div class="steam-settings-block__password-toggle">
                <input :type="tradeLinkInputType" id="trade-link" class="steam-settings-block__input"
                  v-model="newTradeLink" />
                <img class="steam-settings-block__password-icon" v-if="!tradeLinkVisible"
                  src="../../assets/eye-closed.svg" alt="" @click="togglePasswordVisibility('trade-link')" />
                <img class="steam-settings-block__password-icon" v-else src="../../assets/eye-opened.svg" alt=""
                  @click="togglePasswordVisibility('trade-link')" />
              </div>
              <button @click="saveTradeLink" class="steam-settings-block__button profile-settings__button">
                {{ $t('Save Trade Link') }}
              </button>
            </div>
          </div>
          <!-- <div class="steam-settings-block__row status-row">
            <label class="steam-settings-block__label">{{ $t('Inventory Status') }}:</label>
            <div class="steam-settings-block__content">
              <span class="steam-settings-block__status status-public">{{ $t('Public') }}</span>
              <a href="https://steamcommunity.com/my/edit/settings" target="_blank"
                class="steam-settings-block__button profile-settings__button">{{ $t('Open Setting') }}</a>
            </div>
          </div> -->
          <div class="steam-settings-block__row status-row">
            <label class="steam-settings-block__label">{{ $t('Profile Status') }}:</label>
            <div class="steam-settings-block__content">
              <span class="steam-settings-block__status" :class="profileStatusClass">{{ $t(profileStatus) }}</span>
              <a href="https://steamcommunity.com/my/edit/settings" target="_blank"
                class="steam-settings-block__button profile-settings__button">{{ $t('Open Setting') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal v-if="showBlockAccountModal" :closeConfirmationModal="() => showBlockAccountModal = false"
      message="Are you sure you want to temporarily block your account?" confirmButtonText="Block"
      cancelButtonText="Cancel" :onConfirm="blockAccount" />

    <ConfirmationModal v-if="showDeleteAccountModal" :closeConfirmationModal="() => showDeleteAccountModal = false"
      message="Are you sure you want to delete your account? This action cannot be undone." confirmButtonText="Delete"
      cancelButtonText="Cancel" :onConfirm="deleteAccount" />

    <ChangeEmailModal v-if='changeEmailModalVisible' />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import ChangeEmailModal from '../modals/ChangeEmailModal.vue'
import ConfirmationModal from '../modals/ConfirmationModal.vue'
import Vue from 'vue'
export default {
  components: {
    ChangeEmailModal,
    ConfirmationModal
  },
  data() {
    return {
      emailPasswordVisible: false,
      emailPasswordInputType: 'password',
      passwordPasswordVisible: false,
      passwordPasswordInputType: 'password',
      steamIdVisible: false,
      steamIdInputType: 'password',
      tradeLinkVisible: false,
      tradeLinkInputType: 'password',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      newTradeLink: '',
      showBlockAccountModal: false,
      showDeleteAccountModal: false,
    }
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) => languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage
    }),
    ...mapState('app', ['currencies', 'currency']),

    ...mapState('user', ['user']),
    ...mapState('modals', ['changeEmailModalVisible']),
    ...mapGetters('user', ['fullName']),

    language: {
      set(langId) {
        this.changeLocale(langId)
      },
      get() {
        return this.currentLanguage
      }
    },
    currentCurrency: {
      set(currencyCode) {
        this.setCurrency(currencyCode)
      },
      get() {
        return this.currency
      }
    },
    profileStatus() {
      switch (this.user.communityVisibilityState) {
        case 1:
          return this.$t('Private');
        case 3:
          return this.$t('Public');
        default:
          return this.$t('Unknown');
      }
    },
    profileStatusClass() {
      return {
        'status-public': this.user.communityVisibilityState === 3,
        'status-private': this.user.communityVisibilityState === 1
      };
    },
  },
  methods: {
    ...mapActions('modals', ['openChangeEmailModal']),
    ...mapMutations('user', ['setSteamTradeLink']),
    ...mapActions('user', ['resetUserState']),
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id))
    },
    // selectCurrency(event) {
    //   this.setCurrency(event.id)
    // },
    togglePasswordVisibility(inputId) {
      switch (inputId) {
        case 'email':
          this.emailPasswordVisible = !this.emailPasswordVisible
          this.emailPasswordInputType = this.emailPasswordVisible ? 'email' : 'password'
          break
        case 'password':
          this.passwordPasswordVisible = !this.passwordPasswordVisible
          this.passwordPasswordInputType = this.passwordPasswordVisible ? 'text' : 'password'
          break
        case 'steam-id':
          this.steamIdVisible = !this.steamIdVisible
          this.steamIdInputType = this.steamIdVisible ? 'text' : 'password'
          break
        case 'trade-link':
          this.tradeLinkVisible = !this.tradeLinkVisible
          this.tradeLinkInputType = this.tradeLinkVisible ? 'text' : 'password'
          break
      }
    },
    changePassword() {
      let data = {
        cur_password: this.currentPassword,
        new_password: this.newPassword,
        repeat_password: this.confirmPassword
      }
      this.$http
        .post(process.env.VUE_APP_API + 'user/change-password', data)
        .then((res) => {
          if (res.data.status == 'OK') {
            this.successMessage = 'Success'
            Vue.$toast.success('Password changed successfully')
            this.currentPassword = ''
            this.newPassword = ''
            this.confirmPassword = ''
          } else {
            Vue.$toast.error(res.response.data.message)
          }
        })
        .catch((error) => {
          Vue.$toast.error(error.response.data.message)
        })
    },
    saveTradeLink() {
      this.$http.put(process.env.VUE_APP_API + 'user/update-trade-link', { url: this.newTradeLink })
        .then(() => {
          this.setSteamTradeLink(this.newTradeLink)
          this.newTradeLink = ''
          Vue.$toast.success('Trade link updated successfully')
        })
        .catch((error) => {
          Vue.$toast.error(error.response.data.message)
        });
    },
    blockAccount() {
      this.$http.post(process.env.VUE_APP_API + 'user/block')
        .then(() => {
          // Handle successful block
          this.resetUserState();
          this.$router.push({ name: 'Home' });
          Vue.$toast.success(this.$t('Your account has been temporarily blocked.'));
        })
        .catch((error) => {
          // Handle error
          Vue.$toast.error(error.response.data.message);
        });
    },
    deleteAccount() {
      this.$http.delete(process.env.VUE_APP_API + 'user/delete')
        .then(() => {
          // Handle successful deletion
          this.resetUserState();
          this.$router.push({ name: 'Home' });
          Vue.$toast.success(this.$t('Your account has been deleted.'));
        })
        .catch((error) => {
          // Handle error
          Vue.$toast.error(error.response.data.message);
        });
    },
    openBlockAccountModal() {
      this.showBlockAccountModal = true;
    },
    openDeleteAccountModal() {
      this.showDeleteAccountModal = true;
    },
    ...mapMutations('app', ['setCurrentLanguage', 'setCurrency']),
    ...mapActions('app', ['changeLocale']),
  }
}
</script>
