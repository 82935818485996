<template>
  <div class="modal sign-modal login">
    <img class="close" @click="$emit('closeSignUpModal')" src="./../assets/close.svg" />
    <div class="header">
      <div class="header-buttons">
        <a class="link" @click="$emit('openSignInModal')">{{ $t("Login") }}</a>
        <a class="router-link-active">{{ $t("Sign Up") }}</a>
      </div>
    </div>
    <img class="img signIn-left" src="./../assets/login-modal-left-image.png" />
    <div class="overlay"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper signup">
            <div class="form-fields signup-content">
              <div class="form-fields-wrapper">
                <div class="title small">{{ $t("Sign Up") }}</div>
                <button class="button steam">
                  <img src="./../assets/steam.svg" alt="">
                  <span>{{ $t("Sign Up with Steam") }}</span>
                </button>
                <div class="or-separator">
                  <div class="red-ellipse-separator"></div>
                  <div class="or">{{ $t("or") }}</div>
                  <div class="red-ellipse-separator"></div>
                </div>
                <label>
                  <div class="desc">{{ $t("First name") }}:</div>
                  <input type="text" :placeholder="$t('First name')" v-model="name" />
                </label>
                <label>
                  <div class="desc">{{ $t("Last name") }}:</div>
                  <input type="text" :placeholder="$t('Last name')" v-model="surname" />
                </label>
                <label>
                  <div class="desc">E-mail:</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <label>
                  <div class="desc">{{ $t("Phone") }}:</div>
                  <input type="text" :placeholder="$t('Phone')" v-model="phone" />
                </label>
                <label>
                  <div class="desc">{{ $t("Password") }}:</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass" />
                </label>
                <label>
                  <div class="desc">{{ $t("Confirm Password") }}:</div>
                  <input type="password" :placeholder="$t('Confirm Password')" v-model="passConfirm" />
                </label>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="title">{{ $t("I agree with") }} </span>
                        <a @click="goToPage('privacy-policy')" class="title">{{ $t("Privacy Policy") }}</a>
                        <span class="title"> {{ $t("and") }} </span>
                        <a @click="goToPage('terms-and-conditions')" class="title">{{ $t("Terms and conditions") }}</a>
                      </div>
                    </label>
                  </div>
                </div>
                <button class="button" @click="submitRegister">
                  <span>{{ $t("Sign Up") }}</span>
                </button>
                <transition name="slide">
                  <div v-if="$parent.error || error" class="error-desc desc red">{{ $parent.error || error }}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="img signIn-right" src="./../assets/login-modal-right-image.png" />
  </div>
</template>

<script>
export default {
  name: "SignUpModal",
  props: [],
  components: {
  },
  data: function () {
    return {
      email: "",
      pass: "",
      name: "",
      surname: "",
      phone: "",
      passConfirm: "",
      error: "",
      terms: false,
    };
  },
  mounted() {
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (this.email && this.pass && this.passConfirm) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    goToPage(slug) {
      this.$emit('closeSignUpModal');
      this.$router.push({ name: 'TextPage', params: { slug } });
    },
    validateEmail() {
      let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!emailRegex.test(this.email)) {
        this.error = this.$t('Please enter a valid email address');
        return false;
      }
      this.error = "";
      return true;
    },
    validatePassword() {
      let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
      if (!passwordRegex.test(this.pass)) {
        this.error = this.$t('The password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one digit');
        return false;
      }
      this.error = "";
      return true;
    },
    validatePasswordConfirmation() {
      if (this.pass !== this.passConfirm) {
        this.error = this.$t('Password confirmation failed!');
        return false;
      }
      this.error = "";
      return true;
    },
    validateTerms() {
      if (!this.terms) {
        this.error = this.$t('You must agree to the terms and conditions to register');
        return false;
      }
      return true;
    },
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    submitRegister() {
      this.$parent.clearError();
      // if (!this.validateEmail() || !this.validatePassword() || !this.validatePasswordConfirmation()) {
      //   return;
      // }
      if (!this.validateTerms()) {
        return;
      }
      this.error = ""
      let regData = {
        email: this.email,
        password: this.pass,
        passConfirm: this.passConfirm,
        name: this.name,
        surname: this.surname,
        phone: this.phone,
      };
      this.$emit("registration", regData);
    },
  },
};
</script>
