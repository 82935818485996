<template>
  <div class="menu">
    <ul class="nav">

      <template v-for="(item, i) in $parent.nav">
        <li class="nav__item" :key="i">
          <router-link :to="item.link">{{ $t(item.title) }}</router-link>
        </li>
      </template>
      <!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">Sale your skin</a>
			</div> -->

      <!-- Cart icon -->
      <button v-if="$parent.isAuth" class="icon" @click="$parent.toggleCartModal">
        <img src="./../assets/cart-black.svg" class="img" />
        <div class="indicator">
          {{ $parent.cartContents.length || 0 }}
        </div>
      </button>

      <!-- User balance -->
      <router-link to="/profile?tab=account-balance" v-if="$parent.isAuth" class="user-balance">
        <img src="./../assets/wallet-black.svg" class="img wallet" />
        <span>{{ currencySymbol }} {{ user.balance }}</span>
        <img src="./../assets/arrow.svg" class="img arrow" />
      </router-link>

      <DropdownMenu :options="currencies" :handler="handleCurrencyChange" />
    </ul>
  </div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
import DropdownMenu from "@/components/shared/DropdownMenu";
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  name: "Menu",
  props: [],
  components: {
    DropdownMenu
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters('app', ['currencySymbol']),
    ...mapState('app', ['currencies', 'currency']),
  },
  methods: {
    ...mapMutations('app', ['setCurrency']),
    handleCurrencyChange(currencyCode) {
      this.setCurrency(currencyCode);
      this.showDropdown = false;
    },
  },
};
</script>