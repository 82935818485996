<template>
    <div class="modal image-preview-modal">
        <div class="image-preview-modal__overlay" @click="$emit('closeImagePreviewModal')"></div>
        <div class="image-preview-modal-wrapper">
            <div class="image-preview-modal__content">
                <img src="../../assets/close.svg" alt="Close" class="image-preview-modal__close-button"
                    @click="$emit('closeImagePreviewModal')" />
                <div class="image-preview-modal__image-container">
                    <div class="image-preview-modal__image-wrapper">
                        <img :src="previewImageUrl" alt="Preview" class="image-preview-modal__image" />
                    </div>
                </div>
            </div>
            <div class="image-preview-modal__footer">
                <!-- Add any additional content you want in the footer -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImagePreviewModal',
    props: {
        previewImageUrl: String
    }
}
</script>

<style lang="scss" scoped>
.image-preview-modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(47, 45, 45, 0.15);
        z-index: 999;
    }

    &-wrapper {
        background-color: #fbfbfb;
        z-index: 1000;
        max-width: 598px;
        width: 100%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        height: 440px;
        display: flex;
        flex-direction: column;
    }

    &__content {
        padding: 32px 16px 0 16px;
        position: relative;
    }

    &__close-button {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    &__image-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 332px;
    }

    &__image-wrapper {
        position: relative;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/spiral-behind-item-image.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 0;
        }
    }

    &__image {
        max-width: 406px;
        max-height: 308px;
        object-fit: contain;
        position: relative;
        z-index: 1;
    }

    &__footer {
        background-color: var(--bg-main-gray);
        min-height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>