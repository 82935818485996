<!-- DropdownMenu.vue -->
<template>
    <div class="dropdown-container">
        <div class="sort-dropdown">
            <select class="settings-block__select" v-model="selectedOption" @change="handleChange">
                <option v-for="option in options" :value="option.code" :key="option.code">
                    {{ option.code }}
                </option>
            </select>
            <div class="arrow-icon"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'DropdownMenu',
    props: {
        options: {
            type: Array,
            required: true,
        },
        handler: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapState('app', ['currency']),
    },
    mounted() {
        this.selectedOption = this.currency;
    },
    data() {
        return {
            selectedOption: this.currency,
        };
    },
    methods: {
        handleChange() {
            this.handler(this.selectedOption);
        },
    },
};
</script>

<style scoped>
/* Add your styles for the DropdownMenu component */
</style>