<template>
  <div class="modal cart-modal">
    <div class="cart-modal__overlay" @click="$parent.toggleCartModal"></div>
    <div class="cart-modal-wrapper">
      <div class="cart-modal__content">
        <div class="cart-modal__header">
          <h2 class="cart-modal__header-title">{{ $t('Shopping cart') }}</h2>
          <div class="cart-modal__header-actions" @click="$parent.toggleCartModal">
            <div class="cart-modal__header-actions-text">{{ $t('Close') }}</div>
            <img src="../../src/assets/close.svg" alt="Clear cart" class="cart-modal__header-actions-icon" />
          </div>
        </div>

        <div class="cart-modal__separator"></div>
        <div class="cart-modal__items">
          <template v-for="(item, index) in $parent.cartContents">
            <div class="cart-modal__item" :key="item.item.item_id">
              <div class="cart-modal__item-info">
                <img :src="imgDomain + item.item.img_url" alt="Item" class="cart-modal__item-image" />
                <div>
                  <p class="cart-modal__item-info-weapon">{{ getWeaponName(item.item.title) }}</p>
                  <p class="cart-modal__item-info-name">{{ getSkinName(item.item.title) }}</p>
                  <p class="cart-modal__item-info-exterior">{{ getExteriorFromTitle(item.item.title) }}</p>
                </div>
              </div>
              <div class="cart-modal__item-price">
                <div class="cart-modal__item-price-text">{{ currencySymbol }} {{ item.item.price.toFixed(2) }}</div>
                <img src="../../src/assets/trash-bin.svg" alt="Remove" class="cart-modal__item-price-icon"
                  @click="$parent.removeFromCart({ item_id: item.item_id })" />
              </div>
            </div>
            <div class="cart-modal__separator" :key="item.item.item_id"
              v-if="index !== $parent.cartContents.length - 1">
            </div>
          </template>
        </div>
      </div>
      <div class="cart-modal__footer">
        <div class="cart-modal__footer-left">
          <div class="cart-modal__footer-left-count"><span class="bold">{{ $parent.cartContents.length || 0 }}
              {{ $t('items') }}</span> {{ $t('in the cart') }}</div>
          <div class="cart-modal__footer-left-total">{{ $t('Total') }}: {{ currencySymbol }} {{ $parent.totalSum }}
          </div>
        </div>
        <div class="cart-modal__footer-right">
          <button class="cart-modal__footer-right-clear-button button" @click="handleClearCartButton">
            {{ $t('Clear cart') }}</button>
          <button class="cart-modal__footer-right-checkout-button button" @click="switchToCheckout">
            {{ $t('Checkout') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getExteriorFromTitle, getWeaponName, getSkinName } from '../../src/utils/helpers';
export default {
  name: 'SignInModal',
  props: {
    isVerified: Boolean
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: '',
      imgDomain: '',
    }
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    ...mapActions('user', ['clearCart']),
    getExteriorFromTitle,
    getWeaponName,
    getSkinName,
    switchToCheckout() {
      this.$parent.toggleCartModal();
      this.$router.push({ name: 'Checkout' });
    },
    switchToforgotPass() {
      this.$parent.clearError()
      setTimeout(() => {
        this.loginContentVisible = false
        this.forgotPassContentVisible = true
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError()
      setTimeout(() => {
        this.forgotPassContentVisible = false
        this.loginContentVisible = true
      }, 100)
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        email: this.email
      }
      this.$emit('forgotPass', data)
    },
    async handleClearCartButton() {
      const result = await this.clearCart()
      if (result === "CLEAR_CART_ERROR") {
        this.$toast.error('Failed to clear cart. Please try again later.');
      }
      else {
        this.$toast.success('Cart cleared successfully');
      }
    }
  }
}
</script>
