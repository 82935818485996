<template>
    <div class="modal confirmation">
        <img class="close" @click="closeConfirmationModal" src="../../assets/close.svg" />
        <div class="overlay" @click="closeConfirmationModal"></div>
        <div class="wrapper">
            <div class="container">
                <div class="form-fields-wrapper">
                    <div class="modal-header">{{ $t(message) }}</div>
                    <div class="modal-buttons">
                        <button class="modal-button button" @click="confirmAction">{{ confirmButtonText }}</button>
                        <button class="modal-button button" @click="closeConfirmationModal">{{ cancelButtonText }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        message: {
            type: String,
            required: true,
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        onConfirm: {
            type: Function,
            required: true,
        },
        closeConfirmationModal: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        confirmAction() {
            this.onConfirm();
            this.closeConfirmationModal();
        },
    },
};
</script>

<style scoped>
.modal-header {
    font-size: 36px;
    text-align: center;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 20px;
}


</style>