<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="left">
            <div class="title big">
              {{ $t("Unleash the Shadow Warrior Within with Exclusive Skins at Ninja-Skins.com!") }}
            </div>
            <div class="desc">
              {{ $t("Leave your foes puzzled - will they fall to your skill or succumb to your stealth?") }}
            </div>
            <button v-if="!$parent.isAuth" class="button" @click="$parent.openSignInModal">Login</button>
            <router-link v-else to="/products/all" class="button">Shop Now</router-link>
          </div>
          <div class="right">
            <div class="img-container">
              <img src="./../assets/hero.png" class="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="section blending-into-section">
        <img src="./../assets/blending-into-left.png" class="img blending-into-image left" />
        <div class="rectangle">
          <div class="title big">{{ $t("Blending into the shadows isn’t enough in CS:2") }}</div>
          <div class="desc">
            Your gear should strike fear and admiration. <b class="bold">Ninja-Skins.com</b> equips you with an
            arsenal
            where each skin is more than a visual upgrade—it’s a symbol of your stealth and strategy. From ancient
            rarities to the newest designs in the cyber dojo, our collection is unmatched in depth and style.
          </div>
          <router-link to="/products/all" class="button blending-into-button">Shop Now</router-link>
        </div>
        <img src="./../assets/blending-into-right.png" class="img blending-into-image right" />
        <img src="./../assets/blending-into-right-weapon.png" class="img blending-into-image right weapon" />
      </div>

      <div class="section dont-just-play-section">
        <div class="left">
          <h1 class="title big">Don't just play, engage with the cunning of a ninja.</h1>
          <p class="desc">Arm yourself with skins that narrate your saga. Dive into thousands of choices at
            Ninja-Skins.com and ascend to legendary status with each silent victory.</p>
          <router-link to="/" class="button">Sale</router-link>
        </div>
        <div class="right">
          <img src="./../assets/dont-just-play-right.png" alt="" class="img">
        </div>
      </div>

      <div class="section discover-section">
        <div class="rectangle">
          <h1 class="title big">Discover your beloved CS:GO skins reborn in Counter-Strike 2</h1>
          <p class="desc">
            Every texture, every shade, reimagined to elevate your gameplay and showcase your collection like never
            before.
            Step into a world where your achievements and style merge seamlessly, bringing a new depth to the game you
            love.
            Welcome to the next level of personal expression—where your legacy shines brighter.
          </p>
        </div>
        <div class="video-background">
          <video class="lazy-video" autoplay loop muted>
            <source src="./../assets/video/discover-background.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
  },
};
</script>
