<template>
  <div class="account-balance">
    <div class="account-balance__content">
      <div class="account-balance__left">
        <div class="account-balance__block">
          <h2 class="account-balance__heading deposit">{{ $t('Deposit') }}</h2>
          <div class="account-balance__row account-balance__row--card-deposit">
            <div class="account-balance__icon-text">
              <img src="../../assets/card-deposit.svg" alt="Wallet Icon" class="account-balance__icon" />
              <span class="account-balance__text">{{ $t('Card Deposit') }}</span>
            </div>
            <!-- <div class="account-balance__limit">
              <span class="account-balance__label">{{ $t('Your limit') }}:</span>
              <span class="account-balance__value"
                >{{ currencySymbol }}0.00/<span class="red">{{ currencySymbol }}1000.00</span></span
              >
            </div> -->
          </div>
          <div class="account-balance__row enter-amount">
            <span class="account-balance__label">{{ $t('Enter Amount') }}</span>
            <div class="account-balance__input-wrapper">
              <span class="account-balance__currency">{{ currencySymbol }}</span>
              <input type="number" class="account-balance__input" v-model.number="enteredAmount" />
            </div>
            <img src="../../assets/tooltip.svg" alt="Tooltip" class="account-balance__tooltip" />
            <div class="account-balance__tooltip-text">
              <p class="account-balance__label">
                <span class="bold">{{ $t('min') }}</span> {{ $t('deposit amount is') }}
                {{ currencySymbol + minAmount[currency] }}.
              </p>
              <!-- <p class="account-balance__label">
                <span class="bold">{{ $t('max') }}</span> {{ $t('deposit amount is') }} {{ currencySymbol }}1,000.00.
              </p> -->
            </div>
          </div>
          <div class="account-balance__row">
            <span class="account-balance__label">{{ $t('or select') }}:</span>
            <div class="account-balance__separator"></div>
          </div>
          <div class="account-balance__button-group">
            <button
              class="account-balance__button"
              v-for="amount in topUpAmountsForCurrency"
              :key="amount"
              @click="setAmount(amount)"
            >
              {{ currencySymbol }} {{ amount }}
            </button>
          </div>
            <div class="account-balance__row account-balance__row_2">
              <template v-for="item in paymentMethods">
                <button 
                  :key="item.code"
                  v-if="item.currencies.includes(currency)"
                  :class="['btn', 
                    {'account-balance__button account-balance__button--primary': !item.image},
                    {'btn-img': item.image}
                  ]" 
                  @click="handleDeposit(item.code)"
                >
                  <span v-if="!item.image">{{ item.title }}</span>
                  <img v-else :src="imgDomain + item.image" :alt="item.title" class="img" />
                </button>
              </template>
            </div>
        </div>
      </div>

      <div class="account-balance__separator"></div>

      <div class="account-balance__right">
        <div class="account-balance__block">
          <h2 class="account-balance__heading balance">{{ $t('Balance') }}</h2>
          <div class="account-balance__row">
            <div class="wallet-info">
              <img src="../../assets/wallet-black.svg" alt="Wallet Icon" class="wallet-info-icon" />
              <div class="balance-info">
                <div class="balance-text">{{ $t('Total') }}</div>
                <div class="balance-amount">{{ currencySymbol }} {{ user.balance.toFixed(2) }}</div>
              </div>
            </div>
            <router-link :to="{ name: 'Profile', query: { tab: 'deposit-history' } }" class="account-balance__button">
              {{ $t('Deposit History') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Transition name="fade">
      <DepositModal v-show="depositModalVisible" :depositAmount="enteredAmount" :paymentType="paymentType" />
    </Transition>
  </div>
</template>
<script>
  import DepositModal from '../DepositModal.vue'
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    name: 'AccountBalance',
    props: ['paymentMethods', 'topUpAmounts', 'minAmount'],
    components: {
      DepositModal
    },
    data: function () {
      return {
        enteredAmount: 5,
        buttonAmounts: [5, 10, 25, 50, 100, 150, 250, 500],
        paymentType: 'card',
        minDepositAmount: 5,
        imgDomain: ''
      }
    },
    computed: {
      topUpAmountsForCurrency() {
        return this.topUpAmounts[this.currency];
      },
      ...mapState('user', ['user']),
      ...mapState('modals', ['depositModalVisible']),
      ...mapGetters('app', ['currency', 'currencySymbol'])
    },
    watch: {
      minAmount(newVal) {
        this.enteredAmount = newVal[this.currency];
      },
      currency(newVal) {
        this.enteredAmount = this.minAmount[newVal];
      },
    },
    mounted: function () {
      this.imgDomain = process.env.VUE_APP_DOMAIN
      this.enteredAmount = this.minAmount[this.currency];
    },
    methods: {
      ...mapActions('modals', ['openDepositModal']),
      setAmount(amount) {
        this.enteredAmount = amount
      },
      handleDeposit(code) {
        this.paymentType = code;
        if (this.validateAmount()) {
          this.openDepositModal()
        }
      },
      validateAmount() {
        if (this.enteredAmount < this.minDepositAmount) {
          this.enteredAmount = this.minDepositAmount
          this.$toast.error(
            this.$t('Entered amount cannot be less than the minimum deposit amount of') +
              ' ' +
              this.currencySymbol +
              this.minDepositAmount.toFixed(2) +
              '.'
          )
          return false
        } else {
          return true
        }
      }
    }
  }
</script>
<style scoped></style>
