<template>
    <div class="modal change-email">
        <img class="close" @click="closeChangeEmailModal" src="../../assets/close.svg" />
        <div class="header">
            <div class="header-buttons">
                <a class="router-link-active">{{ $t('Change Email') }}</a>
            </div>
        </div>
        <div class="overlay" @click="closeChangeEmailModal"></div>
        <div class="wrapper">
            <div class="container">
                <div class="form-fields-wrapper">
                    <div class="title small">{{ $t('Change Email') }}</div>
                    <label>
                        <div class="description">{{ $t('Current Email') }}:</div>
                        <input type="text" disabled :placeholder="$t('Current Email')" :value="user.email" required />
                    </label>
                    <label>
                        <div class="description">{{ $t('New Email') }}:</div>
                        <input type="text" :placeholder="$t('New Email')" v-model="newEmail" required />
                        <div v-if="!newEmailValid" class="error-description description red">
                            {{ $t('New Email is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Password') }}:</div>
                        <input type="text" :placeholder="$t('Password')" v-model="password" required />
                        <div v-if="!passwordValid" class="error-description description red">
                            {{ $t('Password is required') }}
                        </div>
                    </label>
                    <button class="button change-email-button" @click="handleChangeEmail">
                        <span>{{ $t('Change Email') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue';
export default {
    data() {
        return {
            newEmail: '',
            password: '',
            newEmailValid: true,
            passwordValid: true,
            formValid: true
        }
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('modals', ['changeEmailModal'])
    },
    methods: {
        ...mapActions('user', ['changeEmail', 'logout']),
        ...mapActions('modals', ['closeChangeEmailModal']),
        async handleChangeEmail() {
            this.validateForm();
            if (this.formValid) {
                try {
                    const response = await this.changeEmail({
                        email: this.newEmail,
                        password: this.password
                    });
                    if (response.status === 'OK') {
                        Vue.$toast.success(response.message);
                        this.closeChangeEmailModal();
                        this.logout();
                    } else {
                        Vue.$toast.error(response.message);
                    }
                } catch (error) {
                    if (error.response && error.response.status === 422) {
                        Vue.$toast.error(error.response.message);
                    } else {
                        Vue.$toast.error('Failed to change email. Please try again later.');
                    }
                }
            }
        },
        validateForm() {
            this.newEmailValid = this.newEmail.trim() !== '';
            this.passwordValid = this.password.trim() !== '';
            this.formValid = this.newEmailValid && this.passwordValid;
        },
    }
}
</script>