<template>
  <div class="slider-container">
    <slick ref="slick" :options="slickOptions" class="slider products" @swipe="handleSwipe">
      <ProductCard v-for="item in list" :key="item.id" :item="item" :tag="tag"
        :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="sliderIsClickble" @goToProduct="goToProduct"
        :currency="currency" @addToCart="addToCart" :openImagePreviewModal="openImagePreviewModal" />
    </slick>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick'

export default {
  name: 'SlickItem',
  props: ['list', 'tag', 'addToCartChosenItem', 'currency', 'openImagePreviewModal'],
  components: {
    Slick,
    ProductCard
  },
  data: function () {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        arrows: true,
        infinite: false,
        slidesToShow: this.calculateSlidesToShow(),
        slidesToScroll: this.calculateSlidesToScroll(),
        variableWidth: false,
        dots: false,
        // responsive: [
        //   {
        //     breakpoint: 1200,
        //     settings: {
        //       slidesToShow: this.calculateSlidesToShow(1200),
        //       slidesToScroll: this.calculateSlidesToShow(1200),
        //     },
        //   },
        //   {
        //     breakpoint: 992,
        //     settings: {
        //       slidesToShow: this.calculateSlidesToShow(992),
        //       slidesToScroll: this.calculateSlidesToShow(992),
        //     },
        //   },
        //   {
        //     breakpoint: 768,
        //     settings: {
        //       slidesToShow: this.calculateSlidesToShow(768),
        //       slidesToScroll: this.calculateSlidesToShow(768),
        //     },
        //   },
        //   {
        //     breakpoint: 480,
        //     settings: {
        //       slidesToShow: this.calculateSlidesToShow(480),
        //       slidesToScroll: this.calculateSlidesToShow(480),
        //     },
        //   },
        // ],
      }
    }
  },
  computed: {
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    list() {
      this.reInit()
    },
    deep: true
  },
  methods: {
    handleResize() {
      this.slickOptions.slidesToShow = this.calculateSlidesToShow();
      this.slickOptions.slidesToScroll = this.calculateSlidesToScroll();
      this.$refs.slick.reSlick();
    },
    calculateSlidesToShow() {
      let containerWidth = window.innerWidth - 10 * 2; // 10 - margin-right and 10 - margin-left
      if (window.innerWidth > 768) {
        containerWidth = window.innerWidth - 400 - 28 - 28; // 400 - left sidebar width, 28 - margin-left, 28 - margin-right (1512px figma)
      }
      const cardWidth = 138;
      const gap = 10;
      const slidesToShow = Math.floor((containerWidth + gap) / (cardWidth + gap));
      return slidesToShow;
    },
    calculateSlidesToScroll() {
      return this.calculateSlidesToShow();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick()
      })
    },
    handleSwipe() {
      let self = this
      self.sliderIsClickble = false
      setTimeout(() => {
        self.sliderIsClickble = true
      }, 300)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>
