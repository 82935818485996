<template>
  <div class="transaction-history">
    <div class="transaction-history__content">
      <div class="transaction-history__block">
        <!-- <div class="transaction-history__row service">
          <span class="transaction-history__label">Status:</span>
          <div class="transaction-history__select-wrapper">
            <select class="transaction-history__select" v-model="status" @click="toggleDropdown" @blur="toggleDropdown">
              <option value="" hidden disabled>Choose status ...</option>
              <option value="SUCCESS">Success</option>
              <option value="PENDING">Pending</option>
              <option value="ERROR">Error</option>
            </select>
            <div :class="['arrow-icon', { active: isDropdownOpen }]"></div>
          </div>
        </div> -->
        <div class="transaction-history__row">
          <span class="transaction-history__label">{{ $t('Amount') }}:</span>
          <div class="transaction-history__price-filter">
            <div class="transaction-history__price-min">
              <span class="transaction-history__currency desc">{{ currencySymbol }}</span>
              <input class="transaction-history__input" type="number" v-model="barMinValue" />
              <span class="transaction-history__text desc">{{ $t('min') }}</span>
            </div>
            <div class="transaction-history__divider">-</div>
            <div class="transaction-history__price-max">
              <span class="transaction-history__currency desc">{{ currencySymbol }}</span>
              <input class="transaction-history__input" type="number" v-model="barMaxValue" />
              <span class="transaction-history__text desc">{{ $t('max') }}</span>
            </div>
          </div>
        </div>

        <div class="transaction-history__row">
          <span class="transaction-history__label">{{ $t('Date') }}:</span>
          <date-picker v-model="dateRange" range valueType="format" format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"></date-picker>
        </div>

        <div class="transaction-history__row actions">
          <button class="transaction-history__button clear" @click="handlePurchaseClearButton">{{ $t('Clear')
            }}</button>
          <button class="transaction-history__button apply" @click="applyPurchaseFilters">{{ $t('Apply') }}</button>
        </div>
      </div>

      <div class="transaction-history__table-wrapper">
        <table class="transaction-history__table">
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Date') }}</th>
              <th>{{ $t('Name') }}</th>
              <th>{{ $t('Image') }}</th>
              <th>{{ $t('Price') }}</th>
              <th>{{ $t('Status') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="purchaseLoading" class="transaction-history__loading-row">
              <td colspan="6">
                <Spinner />
              </td>
            </tr>
            <tr v-else-if="purchaseHistory.length === 0" class="transaction-history__no-data-row">
              <td colspan="6">{{ $t('No data found') }}</td>
            </tr>
            <tr v-else v-for="purchase in purchaseHistory" :key="purchase.id + '' + purchase.item_id">
              <td>{{ purchase.id }}</td>
              <td>{{ formatDate(purchase.created_at) }}</td>
              <td>{{ purchase.title }}</td>
              <td>
                <img :src="imgDomain + purchase.img_url" alt="Item Image" class="transaction-history__item-image" />
              </td>
              <td>{{ purchase.sum.toFixed(2) }} {{ purchase.currency }}</td>
              <td> {{ $t(purchase.status) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { formatDate } from '@/utils/helpers'
import Spinner from '@/components/shared/Spinner.vue'
export default {
  props: {},
  components: {
    DatePicker,
    Spinner
  },
  data() {
    return {
      barMinValue: 0,
      barMaxValue: 0,
      dateRange: '',
      status: '',
      isDropdownOpen: false,
      isLoading: false,
      imgDomain: '',
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('user/setPurchaseHistory', [])
    next()
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.fetchPurchaseHistory()
    this.clearPurchaseFilterFields()
  },
  computed: {
    ...mapState('user', ['purchaseHistory', 'purchaseLoading']),
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    ...mapActions('user', ['fetchPurchaseHistory']),
    formatDate,
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    clearPurchaseFilterFields() {
      this.barMinValue = 0
      this.barMaxValue = 0
      this.dateRange = ''
    },
    handlePurchaseClearButton() {
      this.clearPurchaseFilterFields()
      this.applyPurchaseFilters()
    },
    getPurchaseFilters() {
      return {
        amount_min: this.barMinValue,
        amount_max: this.barMaxValue,
        date_from: this.dateRange[0],
        date_to: this.dateRange[1]
      }
    },
    async applyPurchaseFilters() {
      const filters = this.getPurchaseFilters()
      const result = await this.fetchPurchaseHistory(filters)
      if (result === 'FETCH_PURCHASE_HISTORY_ERROR') {
        this.$store.commit('user/setPurchaseHistory', [])
        this.$toast.error('Failed to fetch purchase history')
      }
    }
  }
}
</script>
