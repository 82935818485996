<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-info">
            <div class="profile-info-left">
              <div class="profile-image">
                <img :src="user.avatarMedium || require('@/assets/default-profile-icon.svg')" alt="User Avatar" />
              </div>
              <div class="profile-name">{{ fullName }}</div>
              <div class="separator"></div>
              <div class="wallet-info">
                <img src=".././assets/wallet-black.svg" alt="Wallet Icon" />
                <div class="balance-info">
                  <div class="balance-text">{{ $t('Balance') }}</div>
                  <div class="balance-amount">{{ currencySymbol }} {{ user.balance.toFixed(2) }}</div>
                </div>
                <router-link :to="{ name: 'Profile', query: { tab: 'account-balance' } }" class="balance-add">+
                </router-link>
              </div>
            </div>
            <div class="profile-info-right">
              <div class="steam-id">
                <div class="steam-id-label">Steam ID:</div>
                <div class="steam-id-value">{{ user.steamId || 'N/\A' }}</div>
                <img src=".././assets/copy-icon.svg" alt="" class="steam-id-copy"
                  @click="copyToClipboard(user.steamId || 'N/A')" />
              </div>
            </div>
          </div>
          <div class="profile-tabs">
            <div class="tab-item" :class="{ active: activeTab === 'profile-settings' }"
              @click="updateActiveTab('profile-settings')">
              Profile Settings
            </div>
            <div class="tab-item" :class="{ active: activeTab === 'account-balance' }"
              @click="updateActiveTab('account-balance')">
              Account Balance
            </div>
            <div class="tab-item" :class="{ active: activeTab === 'deposit-history' }"
              @click="updateActiveTab('deposit-history')">
              Deposit History
            </div>
            <div class="tab-item" :class="{ active: activeTab === 'purchase-history' }"
              @click="updateActiveTab('purchase-history')">
              Purchase History
            </div>
          </div>
          <div class="profile-tab-content">
            <ProfileSettings v-if="activeTab === 'profile-settings'" />
            <AccountBalance :minAmount="$parent.minAmount" :topUpAmounts="$parent.topUpAmounts" :paymentMethods="$parent.paymentMethods" v-if="activeTab === 'account-balance'" />
            <DepositHistory v-if="activeTab === 'deposit-history'" />
            <PurchaseHistory v-if="activeTab === 'purchase-history'" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProfileSettings from '../components/tabs/ProfileSettings.vue'
import AccountBalance from '../components/tabs/AccountBalance.vue'
import DepositHistory from '../components/tabs/DepositHistory.vue'
import PurchaseHistory from '../components/tabs/PurchaseHistory.vue'
import { copyToClipboard } from '../utils/helpers'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'Profile',
  props: {
    activeTab: {
      type: String,
      required: true
    }
  },
  components: {
    ProfileSettings,
    AccountBalance,
    DepositHistory,
    PurchaseHistory
  },
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      phoneFull: '',
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',

      countryOptions: [],
      orderHistory: []
    }
  },
  mounted() {
    if (this.$parent.isAuth) {
      this.orderHistory = this.$parent.orderHistory
    } else {
      this.$router.push({ name: 'Home' })
      this.$parent.openSignInModal()
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['fullName']),
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    ...mapActions('user', ['fetchAuthStatus']),
    copyToClipboard,
    updateActiveTab(tab) {
      this.$router.push({ query: { tab } })
    },
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    // getAllCountryCodes() {
    //   this.$http
    //     .get(process.env.VUE_APP_API + 'phone-codes')
    //     .then((res) => {
    //       if (res.data.status == 'OK') {
    //         this.countryCodeList = res.data.payload
    //         this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '')
    //         this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList)
    //       }
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.$parent.openSignInModal()
    //       }
    //     })
    // },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0]
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/
      const match = inputString.match(regex)

      if (match) {
        return match[0]
      } else {
        return '' // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational
    }
    // updateProfileData() {
    //   let profileData = {
    //     email: this.email,
    //     name: this.name,
    //     phone: this.phoneFull,
    //     surname: this.surname
    //   }
    //   this.$http
    //     .put(process.env.VUE_APP_API + 'user/profile', profileData)
    //     .then((res) => {
    //       if (res.data.status == 'OK') {
    //         this.profileUpdateSuccessMessaage = 'Success'
    //         setTimeout(() => {
    //           this.profileUpdateSuccessMessaage = ''
    //         }, 3000)
    //       }
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.$parent.openSignInModal()
    //       }
    //       this.profileUpdateErrorMessaage = res.response.data.message
    //       setTimeout(() => {
    //         this.profileUpdateErrorMessaage = ''
    //       }, 3000)
    //     })
    // },
  }
}
</script>
