<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <transition name="fade">
          <div class="wrapper" v-if="currentPage">
            <div class="title">{{ currentPage.title }}</div>
            <div class="desc" v-html="currentPage.header"></div>
            <div class="desc" v-html="currentPage.content"></div>
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'TextPage',
  props: {
    slug: {
      type: [String, Number],
      required: true
    }
  },
  watch: {
    $route() {
      this.fetchStaticPageById(this.pageId);
    }
  },
  async mounted() {
    // Fetch pages data before trying to fetch a page by ID
    if (!this.pages.length) {
      await this.fetchStaticPages()
      this.fetchStaticPageById(this.pageId)
    } else {
      this.fetchStaticPageById(this.pageId)
    }
  },
  computed: {
    ...mapState('staticPages', ['currentPage', 'pages']),
    ...mapGetters('staticPages', ['getPageIdBySlug']),
    pageId() {
      return this.getPageIdBySlug(this.slug)
    }
  },
  methods: {
    ...mapActions('staticPages', ['fetchStaticPageById', 'fetchStaticPages'])
  },
}

</script>