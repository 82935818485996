<template>
    <div class="modal-overlay">
        <div class="cookies-modal">
            <h3 class="cookies-modal-title">{{ $t('Cookie Greetings from Ninja-Skins!') }}</h3>

            <div class="desc">
                Our website uses cookies and akin technologies to ensure it runs smoothly and caters to your preferences
                and needs. While we automatically implement certain essential cookies necessary for the website's basic
                operations, we also present an array of optional cookies. These are in place to enrich your experience
                and can be adjusted according to your preferences. Curious about how we handle cookies? Our
                <router-link :to="{ name: 'TextPage', params: { slug: 'cookie-notice' } }">Cookie Notice</router-link>
                has all the nitty-gritty details.
            </div>
            <button class="button" @click="acceptCookies">
                {{ $t('Got It') }}
            </button>
            <!-- <button class="button" @click="declineCookies">
                {{ $t('Decline') }}
            </button> -->
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'CookiesConsentModal',
    methods: {
        ...mapActions('modals', ['closeCookiesModal']),
        declineCookies() {
            this.closeCookiesModal()
        },
        acceptCookies() {
            localStorage.setItem('cookiesAccepted', 'true')
            this.closeCookiesModal()
        },
    }
}
</script>

<style lang="scss" scoped>
.cookies-modal-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}
</style>