<template>
  <div class="transaction-history">
    <div class="transaction-history__content">
      <div class="transaction-history__block">
        <div class="transaction-history__row service">
          <span class="transaction-history__label">{{ $t('Status') }}:</span>
          <div class="transaction-history__select-wrapper">
            <select class="transaction-history__select" v-model="depositStatus" @click="toggleDropdown"
              @blur="toggleDropdown">
              <option value="" hidden disabled>{{ $t('Choose status') }} ...</option>
              <option value="SUCCESS">{{ $t('Success') }}</option>
              <option value="PENDING">{{ $t('Pending') }}</option>
              <option value="ERROR">{{ $t('Error') }}</option>
            </select>
            <div :class="['arrow-icon', { active: isDropdownOpen }]"></div>
          </div>
        </div>
        <div class="transaction-history__row">
          <span class="transaction-history__label">{{ $t('Amount') }}:</span>
          <div class="transaction-history__price-filter">
            <div class="transaction-history__price-min">
              <span class="transaction-history__currency desc">{{ currencySymbol }}</span>
              <input class="transaction-history__input" type="number" v-model="depositBarMinValue" />
              <span class="transaction-history__text desc">{{ $t('min') }}</span>
            </div>
            <div class="transaction-history__divider">-</div>
            <div class="transaction-history__price-max">
              <span class="transaction-history__currency desc">{{ currencySymbol }}</span>
              <input class="transaction-history__input" type="number" v-model="depositBarMaxValue" />
              <span class="transaction-history__text desc">{{ $t('max') }}</span>
            </div>
          </div>
        </div>
        <div class="transaction-history__row">
          <span class="transaction-history__label">{{ $t('Date') }}:</span>
          <date-picker v-model="depositDateRange" range valueType="format" format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"></date-picker>
        </div>
        <div class="transaction-history__row actions">
          <button class="transaction-history__button clear" @click="handleDepositClearButton">{{ $t('Clear') }}</button>
          <button class="transaction-history__button apply" @click="applyDepositFilters">{{ $t('Apply') }}</button>
        </div>
      </div>

      <div class="transaction-history__table-wrapper">
        <table class="transaction-history__table">
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Date') }}</th>
              <th>{{ $t('Amount') }}</th>
              <th>{{ $t('Status') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="depositLoading" class="transaction-history__loading-row">
              <td colspan="4">
                <Spinner />
              </td>
            </tr>
            <tr v-else-if="depositHistory.length === 0" class="transaction-history__no-data-row">
              <td colspan="4">{{ $t('No data found') }}</td>
            </tr>
            <tr v-else v-for="order in depositHistory" :key="order.id">
              <td>{{ order.id }}</td>
              <td>{{ formatDate(order.created_at) }}</td>
              <td>{{ order.sum }} {{ order.currency?.code }}</td>
              <td :class="{
            success: order.status === 'Success',
            pending: order.status === 'Pending',
            error: order.status === 'Error'
          }">
                {{ $t(order.status) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { formatDate } from '@/utils/helpers'
import Spinner from '@/components/shared/Spinner.vue'
export default {
  props: {},
  components: {
    DatePicker,
    Spinner
  },
  data() {
    return {
      depositBarMinValue: 0,
      depositBarMaxValue: 0,
      depositDateRange: '',
      depositStatus: '',
      isDropdownOpen: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('user/setDepositHistory', [])
    next()
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.fetchDepositHistory()
    this.clearDepositFilterFields()
  },
  computed: {
    ...mapState('user', ['depositHistory', 'depositLoading']),
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    ...mapActions('user', ['fetchDepositHistory']),
    formatDate,
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    clearDepositFilterFields() {
      this.depositBarMinValue = 0
      this.depositBarMaxValue = 0
      this.depositDateRange = ''
      this.depositStatus = ''
    },
    handleDepositClearButton() {
      this.clearDepositFilterFields()
      this.applyDepositFilters()
    },
    getDepositFilters() {
      return {
        amount_min: this.depositBarMinValue,
        amount_max: this.depositBarMaxValue,
        status: this.depositStatus,
        date_from: this.depositDateRange[0],
        date_to: this.depositDateRange[1]
      }
    },
    async applyDepositFilters() {
      const filters = this.getDepositFilters()
      const result = await this.fetchDepositHistory(filters)
      if (result === 'FETCH_DEPOSIT_HISTORY_ERROR') {
        this.$store.commit('user/setDepositHistory', [])
        this.$toast.error('Failed to fetch deposit history')
      }
    }
  }
}
</script>
