import axios from '@/axios'
import i18n from '@/i18n'

const state = {
  isLoading: false,
  languages: [],
  currentLanguage: localStorage.getItem('language') || '10',
  currencies: [],
  currency: localStorage.getItem('currency') || process.env.VUE_APP_FALLBACK_CURRENCY_CODE
}

const getters = {
  currentLanguage: (state) => state.currentLanguage,
  currentLanguageName: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage)

      if (lang) {
        return lang.title
      }
    }

    return ''
  },
  currentLanguageCode: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage)
      if (lang) {
        return lang.code
      }
    }

    return ''
  },
  allLanguages: (state) => state.languages,
  currency: (state) => state.currency,
  currencySymbol: ({ currencies, currency }) => {
    if (currency) {
      const curr = currencies.find(({ code }) => currency === code)
      return curr ? curr.symbol : process.env.VUE_APP_FALLBACK_CURRENCY_SYMBOL // Use the fallback currency symbol from .env
    }

    return ''
  }
}

const actions = {
  fetchLanguages: async ({ commit, dispatch, state: { currentLanguage } }) => {
    try {
      commit('setLoading', true)
      const {
        data: { status, payload }
      } = await axios.get(process.env.VUE_APP_API + 'languages')
      if (status === 'OK') {
        commit('setLanguages', payload)

        if (!currentLanguage && payload.length) {
          await dispatch('changeLocale', payload[0].id)
          // commit('setCurrentLanguage', payload[0].id);
        } else {
          await dispatch('changeLocale', localStorage.getItem('language'))
          // commit('setCurrentLanguage', localStorage.getItem('language'));
        }
      }
    } catch (e) {
      // console.log(e)
    } finally {
      commit('setLoading', false)
    }
  },
  changeLocale: async ({ commit, getters }, localeId) => {
    if (localeId) {
      commit('setCurrentLanguage', localeId)

      try {
        const { data } = await axios.get('/localizations', {
          params: {
            lang_id: localeId
          }
        })

        if (data) {
          let translations = {}

          if (Array.isArray(data) && data.length) {
            data.forEach((translation) => (translations[Object.keys(translation)[0]] = Object.values(translation)[0]))
          } else if (typeof data === 'object') {
            translations = data
          }

          const { currentLanguageCode } = getters

          i18n.setLocaleMessage(currentLanguageCode, translations)
          i18n.locale = currentLanguageCode
        }
      } catch (e) {
        // console.log(e)
      }
    }
  },
  fetchCurrencies: async ({ commit }) => {
    try {
      commit('setLoading', true)
      const {
        data: { status, payload }
      } = await axios.get(process.env.VUE_APP_API + 'currencies')
      if (status === 'OK') {
        const currencies = payload.map(({ code, symbol, value }) => ({ code, symbol, value }))
        commit('setCurrencies', currencies)

        const storedCurrency = localStorage.getItem('currency')
        if (storedCurrency && currencies.some(({ code }) => code === storedCurrency)) {
          commit('setCurrency', storedCurrency)
        } else {
          commit('setCurrency', currencies.length ? currencies[0].code : process.env.VUE_APP_FALLBACK_CURRENCY_CODE)
        }
      }
    } catch (e) {
      // console.log(e)
      const defaultCurrency = {
        code: process.env.VUE_APP_FALLBACK_CURRENCY_CODE,
        symbol: process.env.VUE_APP_FALLBACK_CURRENCY_SYMBOL,
        value: process.env.VUE_APP_FALLBACK_CURRENCY_VALUE
      }
      commit('setCurrencies', [defaultCurrency])
      commit('setCurrency', defaultCurrency.code)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrentLanguage: (state, language) => {
    state.currentLanguage = language
    localStorage.setItem('language', language)
  },
  setCurrencies: (state, currencies) => (state.currencies = currencies),
  setCurrency: (state, currencyCode) => {
    const currency = state.currencies.find(({ code }) => code === currencyCode)
    if (currency) {
      // console.log(curr.text);
      localStorage.setItem('currency', currency.code)
      state.currency = currency.code
    } else {
      // Handle the case where the currency code is not found in the currencies array
      // console.error(`Currency code "${currency}" not found in the currencies array.`)
      state.currency = process.env.VUE_APP_FALLBACK_CURRENCY_CODE
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
