<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" />
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{ active: menuVisible }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="header-container section">
          <Header :cartContents="cartContents" @logout="logout" @closeCartModal="toggleCartModal"
            v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')" />
        </div>
        <router-view :addToCartChosenItem="addToCartChosenItem" :currency="currency" @addToCart="addToCart"
          @goToProduct="goToProduct" @login="handleLogin" @forgotPass="forgotPass" />
        <div class="footer-container">
          <Footer v-if="!['Profile', 'Checkout'].includes($route.name)" />
        </div>
        <transition name="fade">
          <CartModal v-if="isCartModalVisible" @closeCartModal="toggleCartModal" />
        </transition>
        <!-- <transition name="fade">
          <OfferDetailsModal v-if="offerDetailsModalVisible" @closeOfferDetailsModal="closeOfferDetailsModal"
            :orderToView="orderToView" />
        </transition> -->
        <transition name="fade">
          <Login v-if="signInModalVisible" @closeSignInModal="closeSignInModal" @openSignUpModal="openSignUpModal"
            @login="handleLogin" @forgotPass="forgotPass" :is-verified="isVerified"
            @resendConfirmation="resendConfirmationLink" />
        </transition>
        <transition name="fade">
          <Registration v-if="signUpModalVisible" @closeSignUpModal="closeSignUpModal"
            @openSignInModal="openSignInModal" @registration="handleRegistration" />
        </transition>
        <!-- <transition name="fade">
          <SaleSkinModal v-if="saleSkinModalVisible" @closeSaleSkinModal="closeSaleSkinModal" />
        </transition> -->
        <!-- <transition name="fade">
          <OrderModal :appDomain="appDomain" :orderData="orderData" v-show="orderModalVisible"
            @orderSubmit="orderSubmit" />
        </transition> -->
        <CookiesConsentModal v-if="cookiesModalVisible && !isOnCookiePolicyorNoticePage" />
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
// import OfferDetailsModal from "./components/OfferDetailsModal.vue";
// import SaleSkinModal from './components/SaleSkinModal.vue'
import Login from './components/Login.vue'
import Registration from './components/Registration.vue'
import { loadScriptsFromString } from '@/utils/load_scripts'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import CartModal from './components/CartModal.vue'
import CookiesConsentModal from './components/modals/CookiesConsentModal.vue'
export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    // OfferDetailsModal,
    // SaleSkinModal,
    Registration,
    Login,
    CartModal,
    CookiesConsentModal
  },
  data: function () {
    return {
      pageLoaded: false,
      menuVisible: false,
      // offerDetailsModalVisible: false,
      // saleSkinModalVisible: false,
      nav: [
        {
          id: 'shop',
          title: this.$t('Shop'),
          link: '/products/all'
        },
        {
          id: 'new-skins',
          title: this.$t('New skins'),
          link: '/products/new-arrivals'
        }
      ],
      // isAuth: false,
      // error: "",
      successMessage: '',
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: '',
      newArrivals: [],
      // recommendedProducts: [],
      // saleProducts: [],
      // cartContents: [],
      // totalSum: "0.00",
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: '',
      similarProducts: '',
      imgDomain: '',
      textPageList: '',
      // currency: "",
      hostname: '',
      footerRequisites: '',
      footerSupportEmail: '',
      footerCopyright: '',
      // socialLinks: [],
      isVerified: true,
      // orderModalVisible: false,
      orderData: {},
      minAmount: '',
      paymentMethods: [],
      appDomain: '',
      isCartModalVisible: false,
      topUpAmounts: []
    }
  },
  watch: {
    currency() {
      this.getNewArrivals();
      // if (this.productPageItem) {
      //   this.getProductPageContent(this.productPageItem.id);
      // }
      if (this.isAuth) {
        this.fetchCartContents();
        this.fetchProfile();
      }
    },
    $route() {
      window.scrollTo(0, 0)
      this.closeMenu()
    }
  },
  methods: {
    ...mapActions('modals', [
      'openSignUpModal',
      'closeSignUpModal',
      'openSignInModal',
      'closeSignInModal',
      'openCookiesModal',
      'closeCookiesModal',
      'toggleCartModal',
      'toggleMobileMenu'
    ]),
    ...mapActions('user', ['login', 'register', 'fetchCartContents']),
    ...mapMutations('user', ['setIsAuth', 'setError']),
    // orderSubmit(data, type) {
    //   data.paymentType = type
    //   this.$http
    //     .post(process.env.VUE_APP_API + 'orders/checkout', data)
    //     .then((res) => {
    //       if (res.data.status == 'ERROR') {
    //         this.$router.push({ name: 'PaymentFailPage' })
    //         this.orderModalVisible = false
    //       } else {
    //         window.location = res.data.redirect_url
    //       }
    //     })
    //     .catch((res) => {
    //       this.setError(res.response.data.message)
    //     })
    // },
    // openOrderModal(data) {
    //   if (this.totalSum < 5) {
    //     this.setError('Sorry, but minimum order amount is ' + this.minAmount + ' ' + this.currency)
    //   } else {
    //     this.setError('')
    //     this.orderModalVisible = true
    //     this.orderData = data
    //   }
    // },
    // closeOrderModal() {
    //   this.orderModalVisible = false
    //   this.setError('')
    // },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + 'settings')
        .then((res) => {
          this.paymentMethods = res.data.payload.payment_methods
          this.minAmount = res.data.payload.min_top_up
          this.topUpAmounts = res.data.payload.top_up_amounts
          this.footerRequisites = res.data.payload.requisites
          this.footerSupportEmail = res.data.payload.support_email
          this.footerCopyright = res.data.payload.copyright
          const snippet = res.data.payload.google_tag

          if (snippet) {
            loadScriptsFromString(snippet)
          }
        })
        .catch((error) => {
          console.error('Error fetching Google Tag Manager snippet:', error)
        })
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
    },
    closeMenu() {
      this.menuVisible = false
      document.body.style.overflow = 'auto'
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible
      window.scrollTo(0, 0)
      if (this.menuVisible) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    menuHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    clearError() {
      this.setError('')
    },
    // openOfferDetailsModal(item) {
    //   this.orderToView = item
    //   this.offerDetailsModalVisible = true
    // },
    // closeOfferDetailsModal() {
    //   this.offerDetailsModalVisible = false
    //   this.orderToView = {}
    // },
    // openSaleSkinModal() {
    //   this.saleSkinModalVisible = true
    // },
    // closeSaleSkinModal() {
    //   this.saleSkinModalVisible = false
    // },
    async handleLogin(data) {
      const { success, error } = await this.login(data)
      if (success) {
        this.closeSignInModal()
        this.$router.push({ name: 'Profile' })
      } else {
        this.setError(error)
      }
    },

    resendConfirmationLink(data) {
      this.setError('')
      this.$http
        .post(process.env.VUE_APP_API + 'resendConfirmationLink', { email: data })
        .then((res) => {
          if (res.data.status == 'OK') {
            this.successMessage = this.$t('Confirmation link has been resent.')
          } else {
            this.setError(res.data.message)
          }
        })
        .catch((res) => {
          this.setError(res.response.data.message)
        })
    },
    forgotPass(data) {
      let self = this
      this.$http
        .post(process.env.VUE_APP_API + 'user/reset-password', data)
        .then((res) => {
          if (res.data.status == 'OK') {
            this.successMessage = 'Success'
            this.setError('')
            setTimeout(() => {
              self.successMessage = ''
            }, 2000)
          } else {
            this.setError(res.response.data.message)
          }
        })
        .catch((error) => {
          this.setError(error.response.data.message)
        })
    },
    // registration(data) {
    //   this.clearError();
    //   this.isVerified = true;
    //   let self = this;
    //   this.$http.post(process.env.VUE_APP_API + "register", data)
    //     .then((res) => {
    //       if (res.status === 200 && res.data.status === "OK") {
    //         this.clearError();
    //         self.isAuth = true;
    //         this.closeSignUpModal();
    //         self.$router.push({ path: "/profile" });
    //       } else if (res.status === 202 && res.data.status === "VERIFY") {
    //         this.error = "Your registration is being processed. Please check your email for a verification link.";
    //       } else {
    //         this.error = res.data.message;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status >= 400 && error.response.status < 500) {
    //         this.setError(error.response.data.message);
    //       } else {
    //         this.error = "An error occurred. Please try again later.";
    //       }
    //     });
    // },
    async handleRegistration(data) {
      const { success, error } = await this.register(data)
      if (success) {
        this.closeSignUpModal()
        this.fetchAuthStatus()
        this.$router.push({ name: 'Profile' })
      } else {
        this.setError(error)
      }
    },
    getNewArrivals() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?new=true&currency=' + this.currency)
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.setIsAuth(false)
          }
        })
    },
    // getRecommended() {
    //   this.$http
    //     .get(process.env.VUE_APP_API + 'items/recommended')
    //     .then((res) => {
    //       this.recommendedProducts = res.data.payload
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.setIsAuth(false)
    //       }
    //     })
    // },
    goToProduct(item) {
      this.$router.push({
        path: '/product/' + item.id,
        params: { data: item.id }
      })
      this.getProductPageContent(item.id)
    },
    getProductPageContent(id) {
      this.productPageItem = ''
      this.similarProducts = ''
      this.$http
        .get(process.env.VUE_APP_API + 'items/' + id)
        .then((res) => {
          console.log(res)
          this.productPageItem = res.data.payload
          this.similarProducts = res.data.payload.similar
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.setIsAuth(false)
          }
        })
    },
    // goToPage(item) {
    //   let newItem
    //   if (item == 'privacy') {
    //     for (let i = 0; i < this.textPageList.length; i++) {
    //       if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
    //         newItem = this.textPageList[i].id
    //       }
    //     }
    //   } else if (item == 'terms') {
    //     for (let i = 0; i < this.textPageList.length; i++) {
    //       if (this.textPageList[i].title.toLowerCase().includes('terms')) {
    //         newItem = this.textPageList[i].id
    //       }
    //     }
    //   } else {
    //     newItem = item
    //   }
    //   this.$router.push({
    //     path: '/pages/' + newItem,
    //     params: { data: newItem }
    //   })
    //   this.getTextPageContent(newItem)
    // },
    // getTextPageContent(id) {
    //   this.textPageTitle = ''
    //   this.textPageHeader = ''
    //   this.textPageContent = ''
    //   this.$http
    //     .get(process.env.VUE_APP_API + 'static-pages/' + id)
    //     .then((res) => {
    //       this.textPageTitle = res.data.payload.title
    //       this.textPageContent = res.data.payload.content
    //       this.textPageHeader = res.data.payload.header
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.setIsAuth(false)
    //       }
    //     })
    // },
    // getTextPages() {
    //   this.$http
    //     .get(process.env.VUE_APP_API + 'static-pages')
    //     .then((res) => {
    //       this.textPageList = res.data.payload
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.setIsAuth(false)
    //       }
    //     })
    // },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted')
      if (!cookiesAccepted) {
        this.openCookiesModal()
      }
    },

    toggleCartModal() {
      if (this.isMobileMenuOpen) {
        // Close the mobile menu first
        this.toggleMobileMenu()
      }

      this.isCartModalVisible = !this.isCartModalVisible
      if (this.isCartModalVisible) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen

      // Adjust the body overflow
      if (this.isMobileMenuOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    ...mapActions('app', ['fetchLanguages', 'fetchCurrencies']),
    ...mapActions('user', [
      'fetchProfile',
      'fetchPurchaseHistory',
      'fetchDepositHistory',
      'fetchCartContents',
      'addToCart',
      'removeFromCart',
      'clearCart',
      'fetchAuthStatus',
      'logout'
    ]),
    ...mapActions('staticPages', ['fetchStaticPages']),
  },
  mounted: async function () {
    this.cookiesModal()
    await this.fetchCurrencies()
    await this.fetchLanguages()
    await this.fetchAuthStatus()
    console.log('auth: ' + this.isAuth)
    this.hostname = this.getDomainName(window.location.hostname)
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.appDomain = process.env.VUE_APP_DOMAIN
    this.pageLoaded = true
    let self = this
    window.addEventListener('resize', self.menuHeight)
    self.menuHeight()
    self.getGoogleTagManagerSnippet()
    // this.getRecommended()
    this.getNewArrivals();
    // this.getTextPages()
    this.fetchStaticPages()
  },
  computed: {
    ...mapState({
      currentLanguage: (state) => state.app.currentLanguage
    }),
    ...mapState('modals', ['signUpModalVisible', 'signInModalVisible', 'cookiesModalVisible']),
    ...mapGetters('app', ['currency']),
    ...mapGetters('user', ['totalSum']),
    ...mapState('user', ['cartContents', 'isAuth', 'error']),
      isOnCookiePolicyorNoticePage() {
        return this.$route.path === '/pages/cookie-notice' || this.$route.path === '/pages/cookie-policy'
      }
  }
}
</script>
