<template>
  <div class="card" @mouseover="showAddToCart = true" @mouseleave="showAddToCart = false">
    <div class="card__weapon">
      <span class="card__weapon-name">{{ getWeaponName(item.title) }}</span>
    </div>
    <div class="card__skin">
      <span class="card__skin-name">{{ getSkinName(item.title) }}</span>
    </div>
    <div class="card__exterior">
      <span class="card__exterior-name">{{ getExteriorFromTitle(item.title) }}</span>
    </div>
    <div class="card__preview">
      <!-- <img @click="goToSliderItem(item)" :src="imgDomain + item.img_url" class="img card__image" /> -->
      <img :src="imgDomain + item.img_url" class="img card__image"
        @click="openImagePreviewModal(imgDomain + item.img_url)" />
    </div>
    <div class="card__price">
      <span class="card__price-discount" v-if="item.old_price != '0.00'">{{ item.old_price }} <span
          class="card__price-currency">{{ currency }}</span></span>
      <span><span class="card__price-currency">{{ currency }}</span> {{ item.price.toFixed(2) }}</span>
    </div>
    <div class="card__action-wrapper" v-show="showAddToCart" @click="handleAddToCart(item, item.id)">
      <img src="./../assets/cart.svg" class="img card__add-to-cart-icon" />
      <span>{{ $t('Add to cart') }}</span>
    </div>
  </div>
</template>

<script>
import { getExteriorFromTitle, getWeaponName, getSkinName } from '../../src/utils/helpers';

export default {
  name: 'ProductCard',
  props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag', 'currency', 'openImagePreviewModal'],
  data: function () {
    return {
      imgDomain: '',
      showAddToCart: false
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
  },
  methods: {
    getExteriorFromTitle,
    getWeaponName,
    getSkinName,
    handleAddToCart(item, id) {
      this.$store.dispatch('user/addToCart', { id, count: 1 })
        .then((message) => {
          this.$toast.success(message);
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    goToSliderItem(item) {
      let self = this
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit('goToProduct', item)
        }
      }, 200)
    },
  }
}
</script>
