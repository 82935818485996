import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import ProductListPage from './pages/ProductListPage.vue'
// import NewArrivals from './pages/NewArrivals.vue'
// import Sale from './pages/Sale.vue'
// import Recommend from './pages/Recommend.vue'
// import Cart from './pages/Cart.vue'
import Profile from './pages/Profile.vue'
// import SellOffers from './pages/SellOffers.vue'
// import ProductPage from './pages/ProductPage.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFailPage from './pages/PaymentFailPage.vue'
import TextPage from './pages/TextPage.vue'
import EmailVerification from './pages/EmailVerification.vue'
import PaymentPendingPage from './pages/PaymentPendingPage.vue'
import Checkout from './pages/Checkout.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    //{ path: '/', redirect: '/home' },
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/products/:pageType?',
      name: 'ProductListPage',
      component: ProductListPage
    },
    // {
    //   path: '/new-arrivals',
    //   name: 'NewArrivals',
    //   component: NewArrivals
    // },
    // {
    //   path: '/sale',
    //   name: 'Sale',
    //   component: Sale
    // },
    // {
    // 	path: '/recommend',
    // 	name: 'Recommend',
    // 	component: Recommend
    // },
    {
      path: '/checkout',
      name: 'Checkout',
      component: Checkout
    },
    // {
    // 	path: '/cart',
    // 	name: 'Cart',
    // 	component: Cart
    // },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      props: (route) => ({ activeTab: route.query.tab || 'profile-settings' }),
    },
    {
      path: '/verify-email',
      name: 'EmailVerification',
      component: EmailVerification
    },
    // {
    //   path: '/sell-offers',
    //   name: 'SellOffers',
    //   component: SellOffers
    // },
    // {
    //   path: '/product/:id',
    //   name: 'ProductPage',
    //   component: ProductPage
    // },
    {
      path: '/success-payment',
      name: 'PaymentSuccessPage',
      component: PaymentSuccessPage
    },
    {
      path: '/fail-payment',
      name: 'PaymentFailPage',
      component: PaymentFailPage
    },
    {
      path: '/processing-payment',
      name: 'PaymentPendingPage',
      component: PaymentPendingPage
    },
    {
      path: '/pages/:slug',
      name: 'TextPage',
      component: TextPage,
      
      props: true,
    },
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound,
      props: { title: 'PageNotFound' }
    }
  ]
})

export default router
