<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img" />
          <img src="./../assets/logo-mini.svg" class="img mob" />
        </router-link>
      </div>
      <div class="right">


        <div class="link-container">
          <ul class="nav">
            <DropdownMenu :options="currencies" :handler="handleCurrencyChange" />
            <template v-for="(item, i) in $parent.nav">
              <li class="nav__item" :key="item.id">
                <router-link :to="item.link">{{ $t(item.title) }}</router-link>
              </li>
              <div class="red-ellipse-separator" :key="i"></div>
            </template>

            <!-- Cart icon -->
            <button v-if="$parent.isAuth" class="icon" @click="$parent.toggleCartModal">
              <img src="./../assets/cart-black.svg" class="img" />
              <div class="indicator">
                {{ cartContents.length }}
              </div>
            </button>

            <!-- User balance -->
            <router-link to="/profile?tab=account-balance" v-if="$parent.isAuth" class="user-balance">
              <img src="./../assets/wallet-black.svg" class="img wallet" />
              <span>{{ currencySymbol }} {{ userBalance }}</span>
              <img src="./../assets/arrow.svg" class="img arrow" />
            </router-link>
          </ul>
        </div>

        <!-- User icon and nickname -->
        <div class="header-profile" :class="{ 'show': showDropdown }" @click="toggleDropdown" v-if="$parent.isAuth">
          <img :src="user.avatarMedium || require('@/assets/default-profile-icon.svg')" class="img profile" />
          <span>{{ fullName }}</span>
          <div class="dropdown-menu">
            <router-link to="/profile" class="dropdown-item">{{ $t('Profile') }}</router-link>
            <a href="#" class="dropdown-item" @click.prevent="logout">{{ $t('Logout') }}</a>
          </div>
        </div>

        <div class="modal-open button nav__item login" @click="$parent.openSignInModal" v-if="!$parent.isAuth">
          <span>{{ $t("Login") }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import DropdownMenu from "@/components/shared/DropdownMenu";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "Header",
  props: ["cartContents"],
  components: {
    DropdownMenu
  },
  data: function () {
    return {
      showDropdown: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['fullName']),
    ...mapState('app', ['currencies', 'currency']),
    userBalance() {
      return this.user.balance ? `${this.user.balance.toFixed(2)}` : "0.00";
    },
    ...mapGetters('app', ['currencySymbol']),
  },
  methods: {
    ...mapMutations('app', ['setCurrency']),
    handleCurrencyChange(currencyCode) {
      this.setCurrency(currencyCode);
      this.showDropdown = false;
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    handleClickOutside(event) {
      const profileDropdown = this.$el.querySelector('.header-profile');
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    openSaleSkinModal() {
      this.$emit("openSaleSkinModal");
    },
    logout() {
      this.$emit("logout");
    },
  },
};
</script>
