<template>
  <div class="spinner-wrapper">
    <div class="spinner"></div>
  </div>
</template>

<script>
  export default {
    name: 'Spinner'
  }
</script>

<style scoped>
  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    border: 4px solid #969595;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
