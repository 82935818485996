<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div :class="['top', { active: filtersIsVisible }]">
            <div class="filters">
              <div class="search-container">
                <label for="search" class="search-label">
                  <input type="text" name="search" class="search-input" v-model="searchQuery"
                    :placeholder="$t('Search')">
                  <img class="search" src="./../assets/search.svg" alt="Search" @click="filterProducts">
                </label>
              </div>
              <div class="filters-wrapper">
                <div class="title">{{ $t("Filter") }}
                  <img class="close" @click="resetFilters" src="./../assets/close.svg" />
                </div>
                <div class="filters-price-container">
                  <div :class="['title small', { active: !priceListIsVisible }]"
                    @click="priceListIsVisible = !priceListIsVisible">
                    {{ $t("Price") }}
                  </div>
                  <div :class="['select-container select-container-range', { hidden: !priceListIsVisible }]">
                    <div class="price-filter">
                      <div class="price-min">
                        <span class="desc currency">{{ currencySymbol }}</span>
                        <input type="number" v-model="barMinValue" />
                        <span class="desc text">{{ $t('min') }}</span>
                      </div>
                      <div class="divider">-</div>
                      <div class="price-max">
                        <span class="desc currency">{{ currencySymbol }}</span>
                        <input type="number" v-model="barMaxValue" />
                        <span class="desc text">{{ $t('max') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-type-container" v-if="categoryOptions.length">
                  <div :class="['title small', { active: typeListIsVisible }]"
                    @click="typeListIsVisible = !typeListIsVisible">
                    {{ $t("Type") }}
                  </div>
                  <div :class="['select-container', { hidden: typeListIsVisible }]">
                    <label class="chekbox-label" v-for="(item, i) in categoryOptions" :key="i">
                      <div class="chekbox-label-wrapper">
                        <input type="radio" :name="item" :value="item" v-model="category" />
                        <div class="checkbox"></div>
                        <span class="title">{{ item }}</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="filter-quality-container" v-if="qualityOptions.length">
                  <div :class="['title small', { active: !qualityListIsVisible }]"
                    @click="qualityListIsVisible = !qualityListIsVisible">
                    {{ $t("Quality") }}
                  </div>
                  <div :class="['select-container', { hidden: !qualityListIsVisible }]">
                    <label class="chekbox-label" v-for="(item, i) in qualityOptions" :key="i">
                      <div class="chekbox-label-wrapper">
                        <input type="radio" :name="item" :value="item" v-model="quality" />
                        <div class="checkbox"></div>
                        <span class="title">{{ item }}</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="['products-container', { active: !filtersIsVisible }]">
            <SlickItem v-if="$route.params.pageType === 'all' && $parent.newArrivals.length" :list="$parent.newArrivals"
              :tag="''" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct"
              :currency="currencySymbol" :openImagePreviewModal="openImagePreviewModal" />
            <div class="items-header">
              <div class="cs2-text">{{ getPageTitle() }}</div>
              <div class="sort-container">
                <div class="clear-filters" @click="resetFilters">
                  <img class="close" src="./../assets/close.svg" />
                  <span>{{ $t('Clear filters') }}</span>
                </div>
                <div class="sort-dropdown">
                  <select v-model="sort" @click="toggleDropdown" @blur="toggleDropdown">
                    <option value="" hidden disabled>{{ $t("Default sort") }}</option>
                    <option value="desc">{{ $t('Highest price first') }}</option>
                    <option value="asc">{{ $t('Lowest price first') }}</option>
                  </select>
                  <div :class="['arrow-icon', { active: isDropdownOpen }]"></div>
                </div>
              </div>
            </div>
            <Spinner v-if="isSearchInProgress" />
            <div class="list products" v-if="productList && !isSearchInProgress">
              <ProductCard v-for="(item, i) in productList" :key="i" :item="item"
                :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true" @goToProduct="goToProduct"
                :currency="currencySymbol" @addToCart="addToCart" :openImagePreviewModal="openImagePreviewModal" />
            </div>
          </div>
          <pagination :per-page="perPage" v-if="totalProducts" v-model="activePage" :records="totalProducts"
            @paginate="changePage" />
        </div>
      </section>
    </div>
    <ImagePreviewModal v-if="showImagePreviewModal" :showModal="showImagePreviewModal"
      :previewImageUrl="previewImageUrl" @closeImagePreviewModal="closeImagePreviewModal" />
  </main>
</template>
<script>
import Pagination from "vue-pagination-2";
import ProductCard from "../components/ProductCard.vue";
import SlickItem from "../components/SlickItem.vue";
import Spinner from "../components/shared/Spinner.vue";
import ImagePreviewModal from "../components/modals/ImagePreviewModal.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ProductListPage",
  props: ["addToCartChosenItem"],
  components: {
    Pagination,
    ProductCard,
    SlickItem,
    Spinner,
    ImagePreviewModal,
  },
  created() {
    this.debouncedFilterProducts = _.debounce(this.filterProducts, 400);
  },
  data: function () {
    return {
      perPage: 16,
      imgDomain: "",
      filtersIsVisible: true,
      sort: "",
      searchQuery: "",
      category: "",
      quality: "",
      activePage: 1,
      totalProducts: null,
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 200000,
      productList: {},
      typeListIsVisible: true,
      qualityListIsVisible: false,
      priceListIsVisible: true,
      specialCategory: "",
      isDropdownOpen: false,
      isSearchInProgress: false,
      isRouteChangeInProgress: false,

      showImagePreviewModal: false,
      previewImageUrl: '',

      debouncedFilterProducts: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.isRouteChangeInProgress = true;
    this.resetFilters();
    this.$nextTick(() => {
      this.checkRouteParams();
      this.isRouteChangeInProgress = false;
    });
    next();
  },
  computed: {
    ...mapGetters("app", ["currencySymbol", "currency"]),
  },
  watch: {
    currency() {
      this.filterProducts();
    },
    searchQuery() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.debouncedFilterProducts();
      }
    },
    category() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    quality() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    barMinValue() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    barMaxValue() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    sort() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    // $route() {
    //   this.isRouteChangeInProgress = true;
    //   console.log(this.isRouteChangeInProgress);
    //   this.closeImagePreviewModal();
    //   this.resetFilters();
    //   this.checkRouteParams();
    //   this.isRouteChangeInProgress = false;
    // }
  },
  mounted() {
    this.checkRouteParams();
    this.getCategoryOptions();
    this.getQualityOptions();
  },
  methods: {
    openImagePreviewModal(imageUrl) {
      this.showImagePreviewModal = true;
      this.previewImageUrl = imageUrl;
    },
    closeImagePreviewModal() {
      this.showImagePreviewModal = false;
    },
    getPageTitle() {
      if (this.$route.params.pageType === "new-arrivals") {
        return "New Arrivals";
      } else if (this.$route.params.pageType === "sale") {
        return "Sale";
      } else {
        return "CS 2";
      }
    },
    checkRouteParams() {
      // if (this.$route.params.type == "revolution-collection") {
      //   this.specialCategory = this.$route.params.type;
      // } else if (this.$route.params.type && this.$route.params.type != "all") {
      //   this.category = this.$route.params.type;
      //   this.specialCategory = "";
      // } else if (this.$route.params.type && this.$route.params.type === "all") {
      //   this.category = "";
      //   this.specialCategory = "";
      // }
      this.activePage = 1;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All quality";
      this.category = "All types";
      this.barMinValue = 0;
      this.barMaxValue = 200000;
      this.searchQuery = "";
      this.sort = "";
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      this.isSearchInProgress = true;

      let addCategory = "";
      if (this.category != "All types") {
        addCategory = "type=" + this.category;
      } else {
        addCategory = "type=";
      }

      let addQuality = "";
      if (this.quality != "All quality") {
        addQuality = "&quality=" + this.quality;
      } else {
        addQuality = "";
      }

      let addPrice = "&price_from=" + this.barMinValue + "&price_till=" + this.barMaxValue;
      let addSort = "&sort=" + this.sort;
      let addPage = "&page=" + this.activePage;
      let addSearch = this.searchQuery ? "&search=" + this.searchQuery : "";

      let addPageTypeParam = "&";
      if (this.$route.params.pageType === "new-arrivals") {
        addPageTypeParam = "&new=true";
      } else if (this.$route.params.pageType === "sale") {
        addPageTypeParam = "&sale=true";
      }

      let addCurrency = "&currency=" + this.currency;

      this.$http
        .get(
          process.env.VUE_APP_API +
          "items/list?" +
          addCategory +
          addQuality +
          addPrice +
          addSort +
          addPage +
          addSearch +
          "&limit=" +
          this.perPage +
          "&category=" +
          addPageTypeParam +
          addCurrency
        )
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.pages = res.data.meta.total;
          this.isSearchInProgress = false;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.isSearchInProgress = false;
        });
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newCategories = res.data.payload.types;
          newCategories.unshift("All types");
          this.newCategories = newCategories;
          this.categoryOptions = newCategories;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getQualityOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All quality");
          this.qualityOptions = newQuality;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  },
};
</script>
