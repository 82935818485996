const defaultState = function () {
  return {
    signInModalVisible: false,
    signUpModalVisible: false,
    cartModalVisible: false,
    cookiesModalVisible: false,
    depositModalVisible: false,
    changeEmailModalVisible: false,
    mobileMenuVisible: false,
    bodyOverflow: 'auto'
  }
};

const state = defaultState();


const mutations = {
  setBodyOverflow(state, overflow) {
    state.bodyOverflow = overflow
    document.body.style.overflow = overflow
  },
  setSignUpModalVisibility(state, visible) {
    state.signUpModalVisible = visible
  },
  setSignInModalVisibility(state, visible) {
    state.signInModalVisible = visible
  },
  setCookiesModalVisibility(state, visible) {
    state.cookiesModalVisible = visible
  },
  setCartModalVisibility(state, visible) {
    state.cartModalVisible = visible
  },
  setDepositModalVisibility(state, visible) {
    state.depositModalVisible = visible
  },
  setChangeEmailModalVisibility(state, visible) {
    state.changeEmailModalVisible = visible
  },
  setMobileMenuOpen(state, open) {
    state.mobileMenuVisible = open
  },
}

const actions = {
  openSignUpModal({ commit, dispatch }) {
    commit('setSignUpModalVisibility', true)
    commit('setSignInModalVisibility', false)
    commit('setBodyOverflow', 'hidden')
    dispatch('clearUserError')
  },
  closeSignUpModal({ commit }) {
    commit('setSignUpModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  openSignInModal({ commit, dispatch }) {
    commit('setSignInModalVisibility', true)
    commit('setSignUpModalVisibility', false)
    commit('setBodyOverflow', 'hidden')
    dispatch('clearUserError')
  },
  closeSignInModal({ commit }) {
    commit('setSignInModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  openCookiesModal({ commit }) {
    commit('setCookiesModalVisibility', true)
    // commit('setBodyOverflow', 'hidden')
  },
  closeCookiesModal({ commit }) {
    commit('setCookiesModalVisibility', false)
    // commit('setBodyOverflow', 'auto')
  },
  openDepositModal({ commit }) {
    commit('setDepositModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeDepositModal({ commit }) {
    commit('setDepositModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  openChangeEmailModal({ commit }) {
    commit('setChangeEmailModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeChangeEmailModal({ commit }) {
    commit('setChangeEmailModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  toggleCartModal({ commit, state }) {
    commit('setCartModalVisibility', !state.cartModalVisible)
    if (state.cartModalVisible) {
      commit('setBodyOverflow', 'hidden')
    } else {
      commit('setBodyOverflow', 'auto')
    }
  },
  toggleMobileMenu({ commit, state }) {
    commit('setMobileMenuOpen', !state.mobileMenuVisible)
    commit('setBodyOverflow', state.mobileMenuVisible ? 'hidden' : 'auto')
  },
  clearUserError({ commit }) {
    commit('user/setError', null, { root: true })
  },
  //  openModal(state, name) {
  //     state[`${name}ModalVisible`] = true;
  //     state.bodyOverflow = 'hidden';
  //   },
  //   closeModal(state, name) {
  //     state[`${name}ModalVisible`] = false;
  //     state.bodyOverflow = 'auto';
  //   },
  closeAllModals(state) {
    Object.assign(state, defaultState);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
