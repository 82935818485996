<template>
  <div class="modal sign-modal login">
    <img class="close" @click="$emit('closeSignInModal')" src="./../assets/close.svg" />
    <div class="header">
      <div class="header-buttons">
        <a class="router-link-active">
          {{ $t('Login') }}
        </a>
        <a class="link" @click="$emit('openSignUpModal')">
          {{ $t('Sign Up') }}
        </a>
      </div>
    </div>
    <img class="img signIn-left" src="./../assets/login-modal-left-image.png" />
    <div class="overlay"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper login">
            <div :class="['form-fields login-content', { hidden: !loginContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title small">{{ $t('Login') }}</div>
                <button class="button steam" @click="redirectToSteamAuth">
                  <img src="./../assets/steam.svg" alt="" />
                  <span>{{ $t('Login with Steam') }}</span>
                </button>
                <div class="or-separator">
                  <div class="red-ellipse-separator"></div>
                  <div class="or">{{ $t('or') }}</div>
                  <div class="red-ellipse-separator"></div>
                </div>
                <label>
                  <div class="desc">E-mail:</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <label>
                  <div class="desc">Password:</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass" />
                </label>
                <!-- <div class="cta-container"> -->
                <!-- <div class="link" @click="switchToforgotPass">
                    {{ $t("Forgot password") }}
                  </div> -->
                <button class="button login" @click="submitLogin">
                  <span>{{ $t('Login') }}</span>
                </button>
                <!-- </div> -->
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
                <!-- <button class="button resend-link-button" v-if="!isVerified"
                  @click="$emit('resendConfirmation', email)">
                  <span>Resend Confirmation Link</span>
                </button> -->
              </div>
              <!-- <div class="modal-bottom">
                <div class="desc">{{ $t("Don't have account") }}?</div>
                <div class="link" @click="$parent.openSignUpModal">
                  {{ $t("Register") }}
                </div> -->
              <!-- </div> -->
            </div>
            <div :class="['form-fields forgot-pass-content', { hidden: !forgotPassContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t('Forgot password') }}?</div>
                <label>
                  <div class="desc">{{ $t('Email') }}:</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitForgotPass">
                    <span>{{ $t('Recover') }}</span>
                  </button>
                </div>
                <!-- <transition name="fade"> -->
                <div v-if="$parent.successMessage" class="desc green">
                  {{ $parent.successMessage }}
                </div>
                <!-- </transition> -->
                <!-- <transition name="slide"> -->
                <div v-if="$parent.error" class="error-desc desc red">
                  {{ $parent.error }}
                </div>
                <!-- </transition> -->
              </div>
              <div class="modal-bottom">
                <div class="desc">{{ $t('Have account') }}?</div>
                <div class="link switch-login" @click="switchToLogin()">
                  {{ $t('Login') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="img signIn-right" src="./../assets/login-modal-right-image.png" />
  </div>
</template>

<script>
export default {
  name: 'SignInModal',
  props: {
    isVerified: Boolean
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() { },
  methods: {
    redirectToSteamAuth() {
      window.location.href = `${process.env.VUE_APP_DOMAIN}auth/steam/redirect`
    },
    switchToforgotPass() {
      this.$parent.clearError()
      setTimeout(() => {
        this.loginContentVisible = false
        this.forgotPassContentVisible = true
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError()
      setTimeout(() => {
        this.forgotPassContentVisible = false
        this.loginContentVisible = true
      }, 100)
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        email: this.email
      }
      this.$emit('forgotPass', data)
    }
  }
}
</script>
